@import "../Shared/vars.scss";

.Gallery {
    display: flex;
}

@media only screen and (min-width: 768px) {
    .Gallery {
        display: flex;
    }
}

