@import "../Shared/vars.scss";

.CookieBanner {
  position: fixed;
  bottom: 0;
  width: 100vw;
  background: $green;
  color: white;
  border: 2px solid black;
  padding: 0em 1em;
  z-index: 5;
  display: flex;
  flex-direction: column;
  font-weight: 500;
  box-sizing: border-box;

  & a {
    color: white;
  }

  & button {
    margin: 0.25em 1em 0.25em 0.25em;
    padding: 0.25em 1em;
    font-size: 1.2em;
    border: 1px solid black;
    &:first-of-type {
      background: white;
      color: $green;
      font-weight: bold;
    }
  }
}

.Flex {
  display: flex;
  justify-content: flex-end;
}

@media only screen and (min-width: 760px) {
  .CookieBanner {
    align-items: center;
    flex-direction: row;
    justify-content: space-between;

    & p {
      max-width: 75%;
    }
  }
}
