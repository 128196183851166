@import "../../Shared/vars.scss";

.YourDetails {
  display: flex;
}

.Form label {
  font-weight: 500;
  margin-top: 1em;
  color: black;
  font-size: 12px;
  margin: 1em 0;
  letter-spacing: 0;
}

.Form .Container button {
  margin: 1em 0;
}
@media only screen and (min-width: 768px) {
  .Form .Container input {
    width: 50%;
    margin-right: 1em;
  }
}

.Email {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
}

.Form b {
  margin-right: 1em;
}
