@import "../Shared/vars.scss";

.Nav {
  display: flex;
  // width: $site-width;
  justify-content: center;
  // border-bottom: 1px solid #fcfcfc;
  box-shadow: inset 0 -1px 0 0 #e8e8e8;
}

.Nav ul {
  list-style: none;
  display: flex;
  padding: 0;
  position: relative;
}

.Nav li ul {
  position: absolute;
  padding: 1.5em 2.5em 1.5em 0.5em;
  box-shadow: 1px 2px 2px $background--gray;
}

.Nav a,
.Nav button {
  @include font-body--semi-bold;
  color: $text--black;
  text-decoration: none;
  border: 0;
  background-color: transparent;
  text-transform: uppercase;
  padding: 0.25em 1.5em;
  font-size: 13px;
  letter-spacing: 1.52px;
  line-height: 16px;
  text-align: center;
  width: 100%;
  height: 100%;
  display: flex;
  align-items: baseline;
  & svg {
    padding-left: 0.25em;
  }
}

.SubMenu {
  display: flex;
  flex-direction: column;
  padding: 0.5em;
  align-items: baseline;
  font-size: 0.8em;
  background-color: white;
  z-index: 2;
}

@media screen and (max-width: 500px) {
  .Nav {
    display: none;
  }
}

// .womens {
//   color: #262D33;
//   font-family: Quicksand;
//   font-size: 13px;
//   letter-spacing: 1.52px;
//   line-height: 16px;
//   text-align: center;
// }
