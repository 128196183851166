@import "Shared/styles.scss";
.UserCard {
  @extend .card;
  background: white;
  & a {
    text-decoration: none;
    display: grid;
    grid-template-columns: 1fr 5fr;

    & :local(.name) {
      font-weight: 400;
    }
  }
}

.AdminOtions {
  margin-top: 1em;

  & button {
    border: 1px solid $green;
    color: $green;
    border-radius: $border-radius;
    font-size: 0.8em;
    padding: 0.25em 0.5em;
  }
}
