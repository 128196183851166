@import "../Shared/vars.scss";

.Receipt {
  background: $gray-background;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  padding: 2em;

  & * {
    text-align: center;
  }
  & p,
  & a,
  & b {
    font-size: 12px;
  }
}

.Receipt h2 {
  @include font-heading--regular;
  text-transform: uppercase;
  margin: 0.5em 0;
}

@media only screen and (min-width: 768px) {
  .Receipt {
    display: flex;
    padding: 3em;
  }
}

.Tick {
  color: $main--green;
  display: flex;
  align-items: center;
  justify-content: center;
  border: 2px solid $main--green;
  padding: 1em;
  box-sizing: border-box;
  margin: 0 auto 2em auto;
  width: fit-content;
  border-radius: 100%;
}

.Join {
  background: white;
  @media only screen and (max-width: 768px) {
    width: 90%;
    padding: 1em;
  }

  @media only screen and (min-width: 768px) {
    padding: 2em;
    width: 100%;
  }

  margin: 2em auto;
  max-width: 700px;

  & h2 {
    font-size: 1.25em;
  }
  & p {
    margin: 1em 0;
  }
  & .Buttons {
    display: flex;
    justify-content: space-between;
    margin-top: 1.5em;
    & button,
    & a {
      width: 47.5%;
    }
  }
}
