@import "../../Shared/vars.scss";

.PaymentDetails {
  display: flex;
  & > * {
    margin-bottom: 2em;
  }
}

@media only screen and (min-width: 768px) {
  .PaymentDetails {
    display: flex;
  }
}

.CardElement {
  margin: 0.5em 0 1em 0;
  padding-bottom: 0.5em;
  max-width: 25em;

  &:focus {
    border-bottom: 1px solid $main--green;
  }
}
