@import "./styles.scss";

.AuthForm {
  & main {
    padding: 0 0.75em 0.75em 0.75em;
    display: flex;
    flex-direction: column;
    align-items: center;
    max-width: 30em;
  }
  & fieldset {
    border: 0;
    padding: 1em;
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 100%;
  }
  h1 {
    font-weight: bold;
    color: black;
    font-size: 1.25em;
    margin: 0.5em 0;
  }

  & input {
    padding: 1em;
    font-family: "Quicksand";
    font-weight: bold;
    font-size: 1rem;
  }

  & button {
    font-size: 1em;
    padding: 0.5rem 1rem;
    box-shadow: 1px 2px 1px $dark-gray;
    border-radius: 4px;
    margin: 0 auto;
    letter-spacing: 1px;
    font-weight: 600;

    &.white {
      background: $gray;
    }

    &:disabled {
      background: $gray;
    }
  }
}
