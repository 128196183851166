@import "../Shared/vars.scss";

.ApplyVoucher {
  display: flex;
  background: #f2f2f2;
  width: 100%;
  align-items: center;
  padding: 0.5em 1em;
  box-sizing: border-box;

  & > * > div {
    width: 100%;
    box-sizing: border-box;
    margin-right: 1em;
  }
  & input {
    width: 100%;
    border: 0;
    background: rgba($color: #000000, $alpha: 0);
    border-bottom: 1px solid #979797;
    margin: 0 1em 0 0;
    box-sizing: border-box;
    padding: 0.5em;
  }

  & button {
    margin: 0;
    background: rgba($color: #000000, $alpha: 0);
  }
}

@media only screen and (min-width: 768px) {
  .ApplyVoucher {
    display: flex;
  }
}

.ErrorMessage {
  @extend .ErrorBox;
  margin: 1em 0;
}
.SuccessMessage {
  @extend .GreenBox;
  margin: 1em 0;
}
