@import "../Shared/vars.scss";
.Container {
  background: $background--gray;
  padding: 1em;
}

.Page {
  box-sizing: border-box;
  margin: 0;
  & .Left > section,
  aside > section {
    margin-bottom: 1em;
  }
}

.Left {
  margin-top: 0.5em;
}

@media only screen and (min-width: 768px) {
  .Container {
    padding: 2em;
  }

  .Page {
    display: flex;
    max-width: 1100px;
    margin: 0 auto;

    & > *:first-child {
      width: 60%;
      margin-right: 2em;
      box-sizing: border-box;
    }
    & > *:nth-child(2) {
      width: 40%;
      box-sizing: border-box;
    }
  }

  .Left {
    display: flex;
    flex-direction: column;
    margin: 0 1em 0 0;
    & > * {
      margin-bottom: 2em;
    }
  }
}
