@import "../Shared/vars.scss";

.Menu {
  width: 100%;
  position: fixed;
  top: 0;
  left: 0;
  background-color: white;
  z-index: 4;
  height: 100%;
}

.Menu ul {
  list-style: none;
  padding: 0em;
}

.text {
  color: #262d33;
  @include font-heading--regular;
  text-transform: uppercase;
  font-size: 11px;
  letter-spacing: 1.47px;
  line-height: 13px;
  text-align: left;
}

.Menu button {
  @extend .text;
  width: 100%;
  height: 4em;
  box-sizing: border-box;
  display: flex;
  text-align: left;
  padding-left: 2em;
  align-items: center;

  & svg {
    margin-left: 0.5em;
  }
}

.SubMenu button {
  height: 8em;
  @include font-heading--medium;
  font-weight: bold;
}

@media only screen and (min-width: 768px) {
  .Menu {
    display: flex;
  }
}

.X {
  position: absolute;
  top: 2em;
  right: 2em;
  z-index: 2;
}
// .FooterContainer {
//   position: absolute;
//   bottom: 0;
// }
