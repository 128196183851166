@import "../Shared/vars.scss";

.Settings {
  max-width: 800px;
  margin: 0 auto;
  padding: 2em;
  & img {
    object-fit: contain;
    width: 100%;
  }

  & dd {
    border: 1px solid $dark-gray;
    padding: 0.5em;
    width: fit-content;
    margin-top: 0.5rem;
  }
  & input,
  & textarea {
    @include input;
  }

  & img {
    max-width: 200px;
  }

  & a {
    display: block;
    font-size: 1em;
    margin: 1em;
    text-align: center;
  }
}

.Container {
  background: $gray-background;
  width: 100%;
}
