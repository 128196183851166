@import "../Shared/vars.scss";

.CommentList {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  // height: stretch;

  @media screen and (max-width: 768px) {
    justify-content: end;
    overflow: scroll;
  }
  @media screen and (min-width: 768px) {
    height: auto;
    max-height: 200;
    justify-content: end;
    overflow: scroll;
  }

  & ul {
    display: flex;
    flex-direction: column;
    margin: 0;

    @media screen and (max-width: 768px) {
      justify-content: end;
      overflow: scroll;
    }
  }
}

.CommentListDesktop {
  @extend .CommentList;
  @media screen and (min-width: 768px) {
    height: stretch;
  }
  max-height: 400px;

  & ul {
    overflow: scroll;
    justify-content: flex-start;
  }
}

.CommentListGrid {
  @extend .CommentList;
  margin-bottom: 1em;
  & ul {
    height: 100px;
    justify-content: end;
  }
}
