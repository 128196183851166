@import "../Shared/vars.scss";

.LayoutHeader {
  @include Layout;
  @include font-heading--medium;
  // border-top: 1px solid $background--gray;
  display: flex;
  justify-content: center;
  flex-direction: column;
}

.LayoutHeader h1 {
  text-transform: uppercase;
  font-size: 26px;
  letter-spacing: 3.47px;
  line-height: 31px;
  color: black;
  text-align: center;
  font-weight: 500;
}
.top-selling-favourit {
  color: #000000;
  font-family: NHaasGroteskTXPro;

  line-height: 31px;
  width: 633px;
  text-align: center;
}

.SubTitle {
  @include font-heading--regular;
  width: 100%;
  color: #262d33;
  font-size: 12px;
  letter-spacing: 1px;
  line-height: 19px;
  text-align: center;
  text-transform: uppercase;
  margin-top: 0;
  font-weight: 500;
}

@media screen and (max-width: 600px) {
  .LayoutHeader h1 {
    font-size: 16px;
    margin-bottom: 0.25em;
  }
  .SubTitle {
    font-size: 11px;
  }
}

@media screen and (min-width: 600px) {
  .LayoutHeader {
    flex-direction: row;
  }
  .LayoutHeader h1 {
    margin: 0.75rem 0;
  }
}

.LayoutHeader .Right,
.LayoutHeader .FiltersAndSortBy {
  display: flex;
  justify-content: flex-end;
  align-items: center;
}

// .Buttons {
//   display: grid;
//   grid-template-columns: 1fr 1fr;
//   height: 3em;
//   box-sizing: border-box;
//   margin: 1em;

//   & > * {
//     width: 100%;
//     display: flex;
//   }
// }
.Titles {
  // margin: 0 auto;
}
