@import "./vars.scss";

button.primary,
a.primary {
  background: $green;
  font-size: 1rem;
  text-transform: uppercase;
  text-decoration: none;
  color: white;
  font-family: "Quicksand";
  font-weight: 600;
  letter-spacing: 1px;
  border: 0;
  padding: 0.75rem 2rem;

  &:disabled {
    background: gray;
    cursor: not-allowed;
  }
  &:active {
    background: $pink;
    cursor: not-allowed;
  }
}
button.secondary,
a.secondary {
  background: white;
  font-size: 1rem;
  text-transform: uppercase;
  color: $green;
  font-family: "Quicksand";
  font-weight: 600;
  letter-spacing: 1px;
  border: 1px solid;
  padding: 0.75rem 2rem;
  text-decoration: none;

  &:disabled {
    background: gray;
    cursor: not-allowed;
  }
}

button.small,
a.small {
  padding: 0.5em 0.75em;
}

a.small {
  font-size: 0.75em;
  width: fit-content;
  display: inline-block;
  margin-top: 0.5em;
}
