@import "../Shared/styles.scss";

.CampaignCard {
  @extend .card;
  color: $dark-gray;
  text-decoration: none;
  position: relative;
  background: white;
  & button {
    font-size: 0.6rem !important;
    margin-top: 0.5em;
  }
}

.StarPickButtons {
  display: flex;
  flex-direction: column;

  & button {
    width: fit-content;
    border: 1px solid;
  }
}
