@import "../Shared/vars.scss";

:local(.PhotoGrid) {
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;

  grid-column-gap: 1em;
  grid-row-gap: 2em;
  width: stretch;

  & a {
    background: transparent;
    position: relative;
    display: flex;
    justify-content: center;
  }
  & img {
    height: 26vw;
    width: 26vw;
    object-fit: cover;
    @media only screen and (max-width: 600px) {
      height: 26vw;
      width: 26vw;
    }
  }

  & label {
    position: absolute;
    bottom: 0;
    padding: 0.25em 0.75em;
    color: $dark-gray;
    font-weight: 600;
    background: white;
    max-width: 100%;
    border: 1px solid $pink;
    text-overflow: ellipsis;
    overflow: hidden;

    @media only screen and (max-width: 600px) {
      font-size: 0.7em;
    }
  }
}

:local(.SoldOut) {
  border: 1px solid $pink;
  text-align: center;
  position: absolute;
  bottom: 0;
  padding: 0.25em 1rem;
  color: #cc8f8c;
  font-weight: 500;
  margin-top: 4em;
  background: white;
  width: 100%;
  text-align: center;
  @media only screen and (max-width: 600px) {
    font-size: 0.7em;
  }
}
