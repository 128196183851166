@import "../Shared/vars.scss";

.SortBy {
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  z-index: 1;
}

.SortByExpanded {
  @extend .SortBy;
  box-shadow: 1px 2px 3px $background--gray;
  width: 10em;
}

.SortBy button {
  @include font-heading--medium;
  background-color: white;
  border-radius: 0;
  border: 0;
  padding: 1em;
  text-align: center;
  display: flex;
  align-items: center;
  justify-content: space-around;
  & icon {
    margin-left: 0.5em;
  }
}

.SortBy main {
  font-size: 12px;
  display: flex;
  flex-direction: column;
  width: 100%;
  padding: 1em 0;
  box-sizing: border-box;
  background-color: white;
  & label {
    @include font-heading--medium;
    color: $text--black;
    font-size: 12px;
    line-height: 24px;
    padding: 0.5em 1em;
    display: flex;
    align-items: center;
    &:hover {
      background-color: $background--gray;
    }
    & input {
      display: none;
    }
  }
}
