@import "Shared/vars.scss";
.Container {
  position: fixed;
  height: 100vh;
  width: 100vw;
  background: rgba(0, 0, 0, 0.4);
  z-index: 4;
  @media only screen and (min-width: 768px) {
    display: flex;
    justify-content: center;

    & .Popup {
      max-width: 500px;
    }
  }
}

.Popup {
  position: fixed;
  display: flex;
  justify-content: center;
  align-items: center;
  background: white;
  z-index: 3;
  flex-direction: column;
  padding: 2em 1em;
  margin: 0.5em;
  border: 2px solid $green;
  border-radius: 5px;

  & h1,
  h2 {
    text-align: center;
  }

  & .Close {
    position: absolute;
    top: 1em;
    right: 1em;
  }

  & img {
    width: 50%;
  }

  & .Big {
    font-size: 3em;
    color: $green;
    font-weight: bold;
    margin: 0.25em;
  }
}
