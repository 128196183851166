@import "../Shared/vars.scss";

.Planet {
  display: flex;
  background: black;
  color: white;
  padding: 2em;
  align-items: center;
  font-size: 10px;
  width: fit-content;
  & img {
    width: 20%;
    max-height: 10em;
    margin-right: 2em;
  }
  & h2 {
    @include font-heading--regular;
    text-transform: uppercase;
    margin: 0;
  }
  & div {
    flex-direction: column;
    display: flex;
  }
}

@media screen and (min-width: 768px) {
  .Planet {
    font-size: 12px;
  }
}
