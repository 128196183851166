@import "../Shared/styles.scss";
@import "../Shared/forms.scss";

$border-radius: 0;

.SignUp {
  @extend .GrayPageContainer;
  display: flex;
  align-items: center;
  flex-direction: column;
  margin-top: 1em;

  & header {
    color: $green;
    font-size: 1em;
    display: flex;
    align-items: center;
    text-transform: uppercase;
    padding: 0.5rem 0;

    & h2 {
      font-weight: 500;
      font-size: 1em;
    }

    & > * {
      margin: 0;
    }
  }

  & section {
    height: fit-content;
    width: fit-content;
    margin: auto;
    display: block;
  }

  & main {
    display: flex;
    flex-direction: column;
    background: $dark-gray;
    box-sizing: border-box;
    background: white;
    border-radius: $border-radius;
    max-width: 600px;
    margin: 0 auto;

    & a {
      font-size: 0.8em;
      color: $gray;
      font-weight: 500;
      margin: 0.5em 0 0 0;
    }
  }

  & button[type="submit"] {
    border-radius: $border-radius;
    margin: 0 auto;
    text-transform: uppercase;
    padding: 0.5em 2em;
  }
}

.CustomerSignUpForm,
.DesignerSignUpForm {
  display: flex;
  flex-direction: column;
  padding: 1em 10%;

  & > * {
    display: flex;
    margin: 1em 0;
    justify-content: space-between;
  }

  & label {
    margin-bottom: 0.25em;
    font-weight: bold;
    color: $dark-gray;
  }

  & input,
  & textarea {
    width: stretch;
    background: transparent;
    border: 1px solid rgba(0, 0, 0, 0.2);
    padding: 0.5em;
  }
}

.Upload {
  align-items: center;

  & i {
    background: $green;
    color: $gray;
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 1rem 1.25rem;
    border-radius: $border-radius;
    margin-right: 1em;
  }

  & img {
    width: 45%;
    margin-right: 5%;
    border-radius: $border-radius;
  }
}

.Social {
  flex-direction: column;

  & > *:first-child {
    margin-bottom: 1em;
  }

  & > div {
    display: grid;
    grid-template-columns: 2fr 5fr;
    grid-column-gap: 1em;
    margin-bottom: 1em;
  }
}

.SignUpAs {
  @extend .GrayPageContainer;
  @media screen and (min-width: 768px) {
    padding-top: 3em;
  }
  display: flex;
  align-items: center;
  flex-direction: column;
  & header {
    color: $dark-gray;
    font-size: 1.25em;
  }

  & main {
    display: flex;
    flex-direction: column;
    padding: 1em;
    border-radius: $border-radius;

    & button {
      background: $gray;
      margin-bottom: 1em;
      padding: 0.5rem 4rem;
      border-radius: 5px;
      font-weight: bold;
      font-size: 0.9em;
      color: $green;
    }

    & a {
      margin: 0 auto;
      font-size: 0.8em;
      color: $dark-gray;
      font-weight: bold;
    }
  }
}

.UploadWork {
  flex-direction: column;

  & p {
    font-size: 0.8em;
  }
  & input {
    margin-bottom: 0.5em;
  }

  & ol {
    padding-left: 0;
  }
}

.AgreeToTerms {
  display: grid;
  grid-template-columns: auto 1fr;

  & a {
    font-size: 1em !important;
    color: $dark-gray !important;
  }
}
