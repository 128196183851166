@import "../Shared/vars.scss";

.Like {
  display: flex;
  justify-content: space-between;
  align-items: center;
  border-bottom: 1px solid $gray-background;
  padding-bottom: 0.5em;
}

.LikeLeft {
  display: flex;
  align-items: center;

  & > div {
    display: flex;
    flex-direction: column;
  }
}

.Like svg {
  border-radius: 50%;
  border: 1px solid;
  padding: 0.5em;
  margin-right: 0.5rem;
}
.Liked svg {
  fill: black;
}

.Like a {
  color: $main--green;
  text-transform: uppercase;
  font-size: 12px;
  text-decoration: none;
  letter-spacing: 0.6px;
  font-weight: bold;
  padding-right: 1em;
}
