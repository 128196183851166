@import "../Shared/vars.scss";

.PhotoGrid {
  list-style: none;
  padding: 0;
  margin: 0;
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-gap: 1em;

  & > li {
    width: 100%;
    height: 10em;
    // border: 2px solid $main--green;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 5px;
    position: relative;
  }
  & .WithPhoto {
    height: 100%;
    width: 100%;
    padding: 0;
    color: $main--green;
  }
  & img {
    max-width: 100%;
    max-height: 100%;
    object-fit: contain;
  }
}

.Delete {
  position: absolute;
  top: 0;
  right: 0;
  color: $main--green;
}

.EmptyPhoto {
  color: $main--green;
}
