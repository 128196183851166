@import "../Shared/vars.scss";

.Right {
  display: flex;
  flex-direction: column;
  // height: 100%;
  // justify-content: space-between;
  position: relative;
}

.Title {
  @include font-heading--medium;
  font-size: 30px;
  line-height: 44px;
  text-align: left;
  color: black;
  font-weight: 400;
  text-transform: uppercase;
  margin: 0.5em 1em;
}

.PostPage img {
  max-width: 100%;
  object-fit: cover;
}

@media only screen and (min-width: 768px) {
  .Right > *:last-child {
    position: relative;
    bottom: 0;
    width: 100%;
  }
  .PostPage {
    @include Layout;
    grid-template-columns: 1fr 1fr;
    grid-template-rows: 1fr 1fr 1fr;
  }
  .PostPage > main {
    display: flex;
    & > * {
      width: 50%;
    }
  }

  .PostPage > header {
    grid-column: 2 / span 1;
    & p {
      text-overflow: clip;
    }
  }

  .PostPage > main {
    grid-column: 1 / span 1;
    grid-row: 1 / span 3;
  }
  .PostPage > footer {
    grid-column: 2 / span 1;
    grid-row: 2 / span 2;
  }

  .PostPage > header {
    display: flex;
    height: 3em;
    align-items: center;
    & img {
      border-radius: 50%;
      height: 2em;
      margin-right: 0.75em;
    }
  }
}
