@import "../Shared/vars.scss";

.EmptyBasket {
  background: $background--gray;
  padding: 2em;
}

.EmptyBasket main {
  display: flex;
  flex-direction: column;
  max-width: 400px;
  margin: 0em auto;
  align-items: center;
  & > * {
    margin-bottom: 1rem;
  }

  & h1 {
    @include font-heading--regular;
    letter-spacing: 1px;
    text-transform: uppercase;
    font-size: 18px;
    margin: 0;
  }
  & a {
    width: 100%;
    text-align: center;
  }
  & p {
    text-align: center;
  }
}

@media only screen and (min-width: 768px) {
  .EmptyBasket {
    display: flex;
    padding: 2em;
  }
}
