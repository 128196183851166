@import "Shared/styles.scss";

:local(.BrokenDisplay) {
  margin: 1em;
  height: calc(100vh - 2em);
  display: flex;
  flex-direction: column;

  & h1 {
    font-size: 3em;
  }

  & p {
    font-size: 1.25em;
  }

  & a {
    font-weight: bold;
    color: $green;
  }

  & button,
  :local(.feedback) {
    background: $pink;
    color: white;
    padding: 1rem;
    font-size: 1.25em;
    font-weight: bold;
    width: fit-content;
    border-radius: $border-radius;
    box-shadow: 0 1px 1px 2px $gray;
    margin-bottom: 1em;
    text-decoration: underline;
  }

  :local(.feedback) {
    background: $gray;
    color: $dark-gray;
  }
}
