@import "../Shared/vars.scss";

$footer-width: 1200px;

.Footer {
  @include font-heading--regular;
  @include Layout;
  display: grid;
  grid-template-columns: 1fr 1fr;
  font-size: 12px;
  padding: 1em 0;
  grid-row-gap: 2em;
  margin: 0 2em;
  & a:hover {
    text-decoration: underline;
  }

  & ul {
    list-style: none;
    padding: 0;
  }

  & > section {
    text-transform: uppercase;
  }
}

.FooterSectionHeader {
  @include font-heading--medium;
  font-size: 15px;
  letter-spacing: 2px;
  line-height: 18px;
  text-align: left;
}

.JoinTheMarketPlace {
  width: 100%;
  border: 2px solid $background--gray;
  padding: 2em;
  box-sizing: border-box;
  & > header {
    font-size: 14px;
    letter-spacing: 1.87px;
    line-height: 17px;
  }

  & .Buttons {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-top: 1em;
    & a {
      margin: 0;
      padding: 0 1.5em;
      @media screen and (min-width: 786px) {
        padding: 0 1em;
      }
    }
  }

  & main p {
    @include font-body--regular;
    font-size: 11px;
    line-height: 16px;
    text-transform: none;
  }
}

.LogoContainer {
  margin: 0 1em;
}
@media screen and (min-width: 786px) {
  .LogoContainer {
    max-width: $footer-width;
    margin: 1em auto;
    display: block;
  }
}

.Logo {
  height: 4em;
  margin: 1em 0 1em 0;
}

.JoinTheMarketPlace,
.Socials,
.Bottom {
  grid-column: 1 / span 2;
}

.Footer a {
  @extend .FooterLink;
  text-align: left;
  font-size: 10px;
}
.Footer header {
  font-size: 1.25em;
}

@media screen and (min-width: 500px) {
  .Footer {
    grid-template-columns: 1fr 1fr 1fr;
    margin: 0 auto;
    max-width: $footer-width;
  }
}

@media screen and (min-width: 768px) {
  .Footer {
    grid-template-columns: 1fr 1fr 1fr 1fr;
    grid-template-rows: auto auto;
    & > *:first-child,
    & > *:nth-child(2) {
      grid-row: 1 / span 2;
    }
    max-width: $footer-width;
    margin: 2em auto;
  }
  .JoinTheMarketPlace {
    grid-column: 4 / span 1;
    grid-row: 1 / span 2;
  }
  .Socials {
    grid-column: 3 / span 1;
    grid-row: 2 / span 1;
  }
}

.Bottom {
  background: rgba(0, 0, 0, 0.05);
  width: 100vw;
  padding: 1.5em;
  box-sizing: border-box;
  display: flex;
  justify-content: space-between;
  & a {
    color: black;
  }
  & > p {
    margin: 0;
    font-size: 12px;
  }
}

.Socials {
  display: flex;

  & a {
    margin-left: 0;
    margin-top: 0.5em;
    margin-right: 1em;
  }

  // & svg:hover {
  //   fill: black;
  //   stroke: #333;
  // }
}
