@import "Shared/styles.scss";

.CampaignItem {
  display: grid;
  grid-template-columns: 2fr 5fr;
  grid-column-gap: 1em;
  margin-bottom: 1em;
  & img {
    width: 100%;
  }

  & > div {
    padding-top: 1em;
  }
}

.Box {
  border: 1px solid $pink;
  color: $pink;
  padding: 1em 0.5em;
}

.DesignerDashboard {
  // max-width: 800px;
  margin: 0 auto;
  padding: 2em;
}

.Container {
  width: 100%;
  background-color: $background--gray;
}
