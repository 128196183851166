@import "../Shared/vars.scss";

.QuantityPicker {
  display: flex;
  align-items: center;
  height: 2em;
  width: fit-content;
  border: 1px solid black;
  box-sizing: border-box;

  & > * {
    box-sizing: border-box;
    font-size: 12px;
  }
  & .Button {
    display: flex;
    height: 100%;
    width: 3em;
    color: black;
    justify-content: center;
    align-items: center;
    margin: 0;
    border: 0;
    padding: 0;
  }

  & .QuantityValue {
    @include small-caps;
    height: 100%;
    width: 3em;
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 0;
  }
}
