@import "../Shared/vars.scss";

.ProfileHeader {
  width: 100%;
  background-color: $background--gray;
  display: flex;
  justify-content: center;
  position: relative;
  padding: 3em 0 7em 0;
}

@media screen and (max-width: 650px) {
  .ProfileHeader main {
    padding: 0 10%;
    & img {
      max-width: 40%;
    }
  }
}
@media screen and (min-width: 650px) {
  .ProfileHeader main {
    display: grid;
    grid-template-columns: 1fr 3fr;
    // grid-column-gap: 2em;
    max-width: 700px;
    align-items: center;
    @media screen and (max-width: 600px) {
      margin: 0 1em;
    }
  }
}
.ProfileHeader aside {
  margin-right: 1em;
  & img {
    border-radius: 50%;
    width: 100%;
    aspect-ratio: 1/1;
    object-fit: cover;
  }
}

.Content {
  @include font-body--regular;
  display: flex;
  flex-direction: column;

  & > * {
    margin-bottom: 0.5em;
  }
}

.Content {
  color: $text--black;
}

.Content h1 {
  @include font-heading--medium;
  text-transform: uppercase;
  @media screen and (max-width: 600px) {
    font-size: 1.2em;
    letter-spacing: 3px;
    line-height: 42px;
  }
  font-size: 2em;
  letter-spacing: 4.67px;
  line-height: 42px;
  margin: 0;
}

.Username {
  font-weight: bold;
  @media screen and (max-width: 600px) {
    margin-left: 0;
  }
}

.Bio {
  font-size: 0.8em;
}

.ProfileHeader footer {
  position: absolute;
  bottom: 0;
  background-color: white;
  // width: fit-content;
  display: flex;
  @media screen and (max-width: 650px) {
    width: 80%;
    margin: 0 10%;
    padding: 0;
    left: 0;
  }

  & > * {
    padding: 1em;
    box-sizing: border-box;
    width: 33%;
    & > * {
      text-align: center;
    }
    display: flex;
    flex-direction: column;
    align-items: center;
  }
}

.StatsBlock {
  @include font-body--regular;
  letter-spacing: 0.35px;
  line-height: 28px;
  padding: 0 1em;
  font-weight: lighter;
}
.Stat {
  font-size: 1.5em;
}
