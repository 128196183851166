@import "../../Shared/vars.scss";

.Basket {
  display: flex;
}

@media only screen and (min-width: 768px) {
  .Basket {
    display: flex;
  }
}

.BasketTotal {
  margin: 1em 1em 0 1em;

  & ul {
    list-style: none;
    padding: 0;
    margin-bottom: 0;
  }

  & header {
    @include small-caps;
  }
}

.BasketTotal li {
  @extend .SpaceBetween;
}
