@import "../../Shared/vars.scss";

.DeliveryAddress {
  display: fle;
}

@media only screen and (min-width: 768px) {
  .DeliveryAddress {
    display: flex;
  }
  // .Main input,
  // .Main select {
  //   width: 50%;
  // }
}

.Main label {
  display: flex;
  flex-direction: column;
}

.Main select {
  padding: 0.75em 0.25em;
}
.SelectContainer {
  width: 50%;
}

.Main input {
  margin-bottom: 0.5em;
}
