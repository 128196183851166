@import "../../Shared/vars.scss";

.Error {
  @extend .ErrorBox;
  background-color: rgb(255, 244, 244);
  font-weight: bold;
  text-align: center;
  padding: 1em 4em;

  font-size: 14px;
  position: absolute;
  z-index: 3;

  & i {
    margin-right: 1em;
  }
  @media only screen and (max-width: 960px) {
    width: 80%;
    margin: 0.5em 10% 0 10%;
  }
}

.Container {
  @media only screen and (min-width: 960px) {
    margin: 1em auto;
    max-width: 960px;
    font-size: 1rem;
    display: flex;
    justify-content: center;
  }
}
