@import "../Shared/styles.scss";

.ProfileComponent {
  display: flex;
  flex-direction: column;
}

.ProfileComponent > header {
  margin: 0.5em auto 2em auto;
  display: flex;
}

@media only screen and (min-width: 768px) {
  .ProfileComponent {
    display: flex;
  }
}

.PostCard {
  width: 100%;
  box-sizing: border-box;
  & img {
    width: 100%;
  }
}
