@import "../Shared/vars.scss";

.Profile {
  display: flex;
  flex-direction: column;
}

@media only screen and (min-width: 768px) {
  .Profile {
    display: flex;
  }
}
