$dark-gray: #4e4d53;
$gray: #eee9e7;
$medium-gray: #868686;
$brown: #e5d1c0;
$pink: #cc8f8c;
$green: #0a3030;
$gray-background: #f0f0f0;
$light-gray-background: #f8f8f8;

$border-radius: 8px;
$box-shadow: 1px 1px 3px $gray;

// New colors
$text--black: #262d33;
// $text--gray: #262d33;
$background--gray: #e9e9e9;
$main--green: #0e4d4c;
$placeholder--gray: #939699;
$border--gray: #979797;
$warning--red: #ff4f52;
$site-width: 1200px;
$content-width: 1200px;

$error-red: #ff4f52;
$error-background: rgba(255, 79, 82, 0.1);

$green-background: rgba(79, 255, 79, 0.1);
// New

@mixin Layout {
  margin: 0 1em;
  @media screen and (min-width: 700px) {
    margin: 0 2em;
  }
  @media screen and (min-width: $site-width) {
    max-width: $content-width;
    margin: 0 auto;
  }
}

@mixin font-body--regular {
  font-family: "Quicksand";
  font-weight: 400;
}
@mixin font-body--semi-bold {
  font-family: "Quicksand";
  font-weight: 600;
}
@mixin font-body--bold {
  font-family: "Quicksand";
  font-weight: 700;
}

@font-face {
  font-family: "NHaasGroteskTXPro-55Rg";
  src: url(./fonts/NHaasGroteskTXPro-55Rg.woff2) format("woff2");
  font-display: swap;
}

@font-face {
  font-family: "NHaasGroteskTXPro-65Md";
  src: url(./fonts/NHaasGroteskTXPro-65Md.woff2) format("woff2");
  font-display: swap;
}

@font-face {
  font-family: "NHaasGroteskTXPro-75Bd";
  src: url(./fonts/NHaasGroteskTXPro-75Bd.woff2) format("woff2");
  font-display: swap;
}

@mixin font-heading--regular {
  font-family: "NHaasGroteskTXPro-55Rg";
}
@mixin font-heading--medium {
  font-family: "NHaasGroteskTXPro-65Md";
}
@mixin font-heading--bold {
  font-family: "NHaasGroteskTXPro-75Bd'";
}

@mixin input {
  @include font-body--regular;
  border: 0;
  margin: 0;
  height: 100%;
  width: 100%;
  border-bottom: 1px solid $border--gray;
  padding: 0.75em 0 0.75em 0.75em;
  font-weight: 500;
  box-sizing: border-box;

  &:focus,
  &:focus-visible {
    border-color: #0a3030;
    outline: 0;
  }
}

.Form {
  display: flex;
  flex-direction: column;
  justify-content: space-evenly;

  & label {
    & > *:first-child {
      margin-bottom: 0.5em;
      font-weight: 500;
    }
  }
  & input {
    @include input;
  }

  & .Error {
    color: $warning--red;
    & input {
      color: $warning--red;
      border-color: $warning--red;
    }
  }
}

.Button {
  @include font-heading--regular;
  border: 2px solid $main--green;
  // width: 100%;
  // margin-bottom: 1em;
  font-size: 11px;
  letter-spacing: 1.83px;
  line-height: 13px;
  padding: 0.75em 1em;
  text-transform: uppercase;
  box-sizing: border-box;
  border-radius: 2px;
}

.ButtonPrimary {
  @extend .Button;
  background-color: $main--green;
  color: white;
  text-decoration: none;
}

.ButtonSecondary {
  @extend .Button;
  background-color: transparent;
  color: $main--green;
  text-decoration: none;
}

.ButtonSecondary:hover {
  background: $main--green;
  color: white;
}

.ButtonWarning {
  @extend .Button;
  background-color: transparent;
  color: $warning--red;
  text-decoration: none;
  border-color: $warning--red;
}
.ButtonWarning:hover,
.ButtonWarning:focus,
.ButtonWarning:active {
  background: $warning--red;
  color: white;
}

////

.BookmarkIcon {
  height: 2.5em;
  width: 2.5em;
  border-radius: 50%;
  padding: 0.5em;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
}

.BookmarkIconSaved {
  @extend .BookmarkIcon;
  background: $main--green;
  color: white;
}
.BookmarkIconEmpty {
  @extend .BookmarkIcon;
  background-color: white;
  color: $text--black;
}

.BookmarkIconWithText {
  @extend .BookmarkIcon;
  background: $main--green;
  color: white;
  width: 7em;
  border-radius: 2px;
  & svg {
    margin-right: 0.5em;
  }
}

.BookmarkIconWithTextSaved {
  @extend .BookmarkIconWithText;
  background: $main--green;
  color: white;
}
.BookmarkIconWithTextEmpty {
  @extend .BookmarkIconWithText;
  background-color: white;
  color: $text--black;
}

////

@media screen and (max-width: 600px) {
  .Grid {
    @include Layout;
    display: flex;
    flex-wrap: wrap;
    & > * {
      margin-bottom: 1.5em;
    }
  }
}

.Grid {
  @include Layout;
  // display: grid;
  // grid-template-columns: 1fr;
  // grid-column-gap: 2em;
  // grid-row-gap: 2.5em;

  // TODO figure out where else to put this
  // & a {
  //   background: rgba(0, 0, 0, 0.2);
  // }
}

.GridLargeMobile {
  @extend .Grid;
  display: grid;
  grid-template-columns: 1fr;
  grid-column-gap: 2em;
  grid-row-gap: 2.5em;
}

.SmallGrid {
  @include Layout;
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  grid-column-gap: 1em;
  grid-row-gap: 1em;
  margin-top: 2em;
  // & img {
  //   max-height: 28vw;
  // }
}

@media only screen and (min-width: 700px) {
  .Grid {
    display: grid;
    grid-template-columns: 1fr 1fr;
    grid-column-gap: 2em;
    grid-row-gap: 2em;
    padding: 0 1em;
  }
  .SmallGrid {
    grid-column-gap: 2em;
    grid-row-gap: 2em;
  }
}

@media only screen and (min-width: 1200px) {
  .Grid {
    grid-template-columns: 1fr 1fr 1fr;
    grid-column-gap: 2em;
    grid-row-gap: 2em;
    max-width: 1200px;
    margin: auto;
  }
  .SmallGrid {
    grid-column-gap: 4em;
    grid-row-gap: 4em;
  }
}

.SmallDate {
  @include font-body--regular;
  color: rgba(0, 0, 0, 0.6);
  font-style: italic;
  font-size: 0.8em;
  font-weight: 400;
  margin-right: 0.25em;
}

.Price {
  @include font-body--regular;
  font-weight: 800;
  @media screen and (max-width: 600px) {
    font-size: 0.75em;
    margin: 0;
    letter-spacing: 1px;
  }
  font-size: 1.2em;
  letter-spacing: 2.13px;
  margin: 0.5em 0 0 0;
}

@mixin small-caps {
  @include font-heading--medium;
  font-weight: 600;
  font-size: 12px;
  letter-spacing: 2px;
  line-height: 18px;
  text-align: left;
  text-transform: uppercase;
}

.SubTitle {
  @include font-heading--regular;
  width: 100%;
  color: #262d33;
  font-size: 14px;
  letter-spacing: 1px;
  line-height: 19px;
  text-align: center;
  text-transform: uppercase;
  margin-top: 0;
}

.Username {
  @include font-heading--regular;
  letter-spacing: 1.6px;
  line-height: 14px;
  margin-left: 0.5em;
  font-size: 0.7em;
  color: $text--black;
  text-transform: uppercase;
  text-overflow: ellipsis;
}

.SmallPrice {
  @include font-heading--medium;
  color: $text--black;
  font-weight: 700;
  font-size: 12px;
  letter-spacing: 1px;
  line-height: 16px;
}

.SpaceBetween {
  display: flex;
  justify-content: space-between;
  border-bottom: 1px solid $background--gray;
  padding: 0.75em 0;
  align-items: center;

  & > *:first-child {
    @include font-heading--medium;
    font-size: 10px;
    letter-spacing: 1.33px;
    line-height: 12px;
    text-transform: uppercase;
    color: $text--black;
  }
}

.LandingPageTitle {
  @include font-heading--regular;
  text-transform: uppercase;
  font-size: 22px;
  letter-spacing: 3.47px;
  line-height: 26px;
  text-align: center;
  margin: 1rem 0;
}

.ErrorBox {
  color: $error-red;
  background: $error-background;
  border: 1px solid $error-red;
  padding: 0.5em;
  font-size: 12px;
  box-sizing: border-box;
}

.GreenBox {
  @extend .ErrorBox;
  color: $main--green;
  background: $green-background;
  border: 1px solid $main--green;
}

.FooterLink {
  color: #262d33;
  font-family: Quicksand;
  font-size: 11px;
  letter-spacing: 1.49px;
  line-height: 28px;
  font-weight: 600;
  text-align: center;
  margin: 0 auto;
  display: block;
  text-decoration: none;
  text-transform: uppercase;
}

.ContinueShopping {
  color: #0e4d4c;
  @include font-body--semi-bold;
  font-size: 13px;
  letter-spacing: 1px;
  line-height: 20px;
  opacity: 0.85;
  text-align: right;
  text-transform: uppercase;
  text-decoration: none;
  display: flex;
  align-items: center;
  justify-content: center;
  margin: 0 auto;
  & svg {
    margin-right: 0.5em;
  }
}

.center {
  text-align: center;
}
