@import "../Shared/vars.scss";

.Bookmark {
    display: flex;
}

@media only screen and (min-width: 768px) {
    .Bookmark {
        display: flex;
    }
}

