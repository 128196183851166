@import "Shared/vars.scss";

.Apply {
  max-width: 800px;
  margin: 0 auto;
  & h1,
  & h2 {
    @include font-heading--medium;
  }
  & li input,
  & textarea {
    width: stretch;
    padding: 0.5em;
    border: 1px solid $medium-gray;
    margin-bottom: 1em;
  }
}
