@import "../Shared/vars.scss";

.BottomMenu {
  display: flex;
  list-style: none;
  margin: 0;
  padding: 0;
  justify-content: space-evenly;
  position: fixed;
  bottom: 0;
  z-index: 3;
  background: white;
  border-top: 1px solid #efefef;
  padding-top: 0.25em;

  & > li {
    width: 20vw;
    & a {
      width: 100%;
      height: 100%;
      padding: 0;
      margin: 0;
      display: flex;
      align-items: center;
      justify-content: flex-end;
      flex-direction: column;
      text-decoration: none;
      text-transform: uppercase;
      color: black;

      & svg {
        stroke-width: 1;
      }

      & span {
        margin: 0.5em 0;
        color: $main--green;
        font-weight: bold;
        font-size: 9px;
      }
    }
  }
}

.Post {
  color: white;
}

@media only screen and (min-width: 600px) {
  .BottomMenu {
    display: none;
  }
}
