@import "../../Shared/vars.scss";

.CheckoutSection {
  display: flex;
  flex-direction: column;
  padding: 1em;
  background-color: white;
  & header {
    border-bottom: 1px solid #e8e8e8;
    padding-bottom: 0.5em;

    & > *:first-child {
      @include small-caps;
    }
    display: flex;
    justify-content: space-between;
    align-items: center;
  }

  & > main,
  & header > *:nth-child(n + 2) {
    @include font-body--regular;
    font-size: 0.85em;
  }

  & > main {
    padding: 1em 0;
  }
}

.CheckoutSection a {
  @include font-heading--regular;
  color: $main--green;
  text-transform: uppercase;
  font-size: 0.8em;
}

@media only screen and (min-width: 768px) {
  .CheckoutSection {
    display: flex;
  }
}

// TODO check
.CheckoutSection > button {
  margin-bottom: 1em;
}
.CheckoutSection input {
  @include input;
}
