@import "Shared/vars.scss";

.PasswordReset {
  padding: 1em;
  max-width: 1200px;

  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: c;
  margin: 0 auto;

  & a {
    color: $main--green;
  }

  & h1 {
    color: $dark-gray;
  }

  & input {
    font-size: 1em;
    padding: 0.5em;
    width: 70%;
  }

  & section {
    margin: 1em 0;
    border-radius: 8px;
  }

  & label {
    margin: 1em 0;
    display: block;
    font-weight: bold;
  }

  & button {
    display: block;
    padding: 1em;
    background: $green;
    color: white;
    margin: 1em 0;
  }
}

.PasswordReset h1,
.PasswordReset label {
  @include font-heading--regular;
}
