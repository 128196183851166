@import "Shared/styles.scss";

@keyframes spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

:local(.LoadingSpinner) {
  display: flex;
  justify-content: center;
  align-items: center;
  // height: 100vh;
  flex-direction: column;
  color: $green;

  & i {
    animation: spin 2000ms infinite;
  }
}
