@import "../Shared/vars.scss";

// .AboutUs h1 {
//   @include font-heading--medium;
//   text-transform: uppercase;
// }

// .AboutUs h2 {
//   @include font-heading--regular;
//   text-transform: uppercase;
// }
$max-width: 1080px;

.AboutUs section:nth-of-type(n + 2) {
  padding: 4em 0;
}

.AboutUs h2 {
  padding: 0 2em;
  margin: 0;
}
.SquareImg {
  width: 90%;
  margin: 5%;
  box-sizing: border-box;
  aspect-ratio: 1/1;
  object-fit: cover;
}

.AboutUs {
  & p,
  & ul {
    margin: 1em;
    font-size: 12px;
    box-sizing: border-box;
    line-height: 1.7;
  }
  & .Bold {
    font-weight: bold;
  }
}

.AboutUs ul {
  margin-bottom: 2em;
}
.AboutUs li {
  padding: 0.5em;
}

.Hero {
  width: 100%;
  display: block;
}
.HeroCopy {
  display: flex;
  flex-direction: column;

  align-items: center;
  text-align: center;
  padding: 1em 2em;
  & a {
    margin: 2em 0;
  }
}

.OurMission,
.Community {
  background: #f2f2f2;
}
.HowItWorks {
  box-shadow: inset 0 -1px 0 0 #e8e8e8, inset 0 1px 0 0 #e8e8e8;

  & img {
    width: 100%;
  }
}

.Future {
  max-width: 600px;
  margin: auto;
}
.Future p {
  text-align: center;
  padding: 1em 2em;
}

.Future a {
  @extend .FooterLink;
}

.GetInContact {
  color: #262d33;
  @include font-heading--regular;
  font-size: 10px;
  letter-spacing: 0.5px;
  line-height: 12px;
  text-align: center;
  margin: 1em auto;
  text-transform: uppercase;
}

.AboutTitle {
  color: #000000;
  @include font-heading--medium;
  font-size: 15px;
  letter-spacing: 2px;
  line-height: 18px;
  text-align: center;
  text-transform: uppercase;
}

.AboutMainTitle {
  @extend .AboutTitle;
  font-size: 21px;
  line-height: 25px;
}

.AboutSubTitle {
  color: #262d33;
  @include font-heading--regular;
  font-size: 10px;
  letter-spacing: 0.5px;
  line-height: 12px;
  text-align: center;
  text-transform: uppercase;
  margin-bottom: 1em;
}

.TakeCareHeader {
  @extend .AboutSubTitle;
  text-align: left;
  padding: 1em;
  margin-bottom: 0;
  margin-top: 2em;
}
.TakeCareList {
  font-size: 12px;
}

.HideWeTakeCare {
  @extend .AboutSubTitle;
}

@media only screen and (min-width: 768px) {
  .HeroCopy {
    background: #f2f2f2;
  }
}

@media only screen and (min-width: 768px) {
  .AboutUs h2 {
    padding: 0;
  }

  .AboutUs p {
    font-size: 16px;
  }
  .AboutSubTitle {
    font-size: 15px;
    letter-spacing: 2px;
    line-height: 18px;
  }
  .AboutTitle {
    font-size: 26px;
    letter-spacing: 3.47px;
    line-height: 31px;
  }
  .AboutMainTitle {
    font-size: 50px;
    line-height: 55px;
    margin: 0;
    font-weight: 400;
  }

  .HeroContainer {
    display: flex;
    flex-direction: row-reverse;
    padding: 0;
    & > * {
      width: 50%;
    }
    & img {
      width: 50vw;
      box-sizing: border-box;
      max-height: 600px;
      object-fit: cover;
      object-position: right;
    }
  }
  .HeroCopy {
    display: flex;
    flex-direction: column;
    justify-content: center;
    padding: 1em 2em 1em 10em;
  }
  .HeroCopy header {
    display: flex;
    flex-direction: column;
    justify-content: center;

    & > * {
      text-align: left;
    }
  }

  .HeroCopy p {
    color: #000000;
    font-size: 18px;
    letter-spacing: 0.25px;
    line-height: 26px;
    text-align: left;
    margin-left: 0;
  }

  .Hero {
    width: 100%;
    object-fit: cover;
  }

  .HowItWorks {
    display: grid;
    grid-template-rows: auto auto auto;
    grid-template-columns: 1fr 1fr;
    margin: auto;
    grid-column-gap: 2em;
    grid-row-gap: 1em;
    max-width: $max-width;
    & h1 {
      grid-row: 1 / span 1;
      grid-column: 1 / span 2;
      text-align: left;
      margin: 0;
    }
    & h2 {
      grid-row: 2;
      text-align: left;
      padding: 0;
    }
  }

  .HideWeTakeCare {
    display: none;
  }

  .TwoColumnGrid {
    display: grid;
    grid-template-columns: 1fr 1fr;
    grid-template-rows: auto auto 70%;
    grid-column-gap: 3em;
    max-width: $max-width;
    margin: auto;

    // TODO big paragraph margin top on desktop size.
    // & p {
    //   font-size: 1.5em;
    // }

    & > img {
      grid-row: 1 / span 3;
    }
  }
}

.TwoColumnGridImageLeft {
  @extend .TwoColumnGrid;
  & > img {
    grid-column: 1;
  }
}

.TwoColumnGridImageRight {
  @extend .TwoColumnGrid;
  & > img {
    grid-column: 2;
  }
}
.Steps {
  display: block;
  background-color: $background--gray;
  padding: 1em;
  margin: 2em 0;

  @media only screen and (min-width: 768px) {
    padding: 1em 2em;
    margin: 0;
  }

  .Icons {
    display: flex;
    justify-content: space-evenly;
    align-items: center;

    & > * {
      display: flex;
      flex-direction: column;
      align-items: center;
      margin: 1em 0;
      @media only screen and (min-width: 768px) {
        margin: 2em 0;
      }
      text-align: center;
      box-sizing: border-box;
      width: 33.3333%;
    }

    & p {
      margin: 1em 0;
    }
  }

  & img {
    max-width: 4em;
  }

  & a {
    display: block;
    width: 80%;
    margin: 0 auto;
    text-align: center;
  }
  @media only screen and (min-width: 768px) {
    & a {
      width: 50%;
    }
  }
}
