@import "../Shared/vars.scss";

.Comment {
  display: grid;
  grid-template-columns: 3.5em auto 10% 10%;
  grid-column-gap: 0.5em;
  border-top: 1px solid $background--gray;
  padding: 0.75em 0.5em;
  box-sizing: border-box;
  align-items: center;
  font-size: 12px;
}

.Comment img {
  height: 3em;
  width: 3em;
  border-radius: 50%;
}

.Username {
  @include font-body--semi-bold;
}

.Body {
  @include font-body--regular;
  margin: 0;
}

@media only screen and (min-width: 768px) {
  .Comment {
  }
}

.OpenDrawer {
  display: flex;
  padding: 0.25em 10%;
  align-items: center;
  justify-content: center;
  & button {
    width: stretch;
  }
  @media only screen and (min-width: 768px) {
    justify-content: flex-end;
    & button {
      width: auto;
    }
  }
}
