@import "../Shared/vars.scss";

.Header {
  @include Layout;
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin: 0.25em auto;
  // height: 5em;
}
.Left {
  width: 25%;
  position: relative;
}

.Title {
  @include font-heading--medium;
  text-transform: uppercase;
  display: flex;
  flex-direction: column;
  align-items: center;
  font-size: 14px;
  width: 60%;

  & h1 {
    margin: 0 0 0 0;
    font-size: 16px;
    font-weight: 500;
  }
  & h2 {
    opacity: 0.8;
    margin: 0;
    font-size: 1em;
    font-weight: 500;
    text-align: center;
  }
}

.Icons {
  display: flex;
  justify-content: flex-end;
  width: 25%;
  box-sizing: border-box;

  & > * {
    margin: 0.5em 0.25em 0.5em 0.5em;
  }
  @media screen and (min-width: 768px) {
    & > * {
      margin-left: 1em;
    }
  }
  & > *:hover {
    background-color: #efefef;
    border-radius: 50px;
  }

  & a {
    color: black;
  }
}

.QuantityInBag {
  background-color: #0e4d4c;
  border-radius: 50%;
  position: absolute;
  top: -0.75em;
  right: -0.75em;
  color: white;
  font-size: 0.75em;
  font-weight: bold;
  display: flex;
  justify-content: center;
  align-items: center;
  height: 1.5em;
  width: 1.5em;
}

.Bag {
  position: relative;
}

.MenuIcon {
  display: flex;
  align-items: center;
  font-size: 8px;
  text-transform: uppercase;

  & svg {
    padding-right: 0.5em;
  }
}

$breakpoint--mobile: 600px;
@media only screen and (max-width: $breakpoint--mobile) {
  .Bookmark,
  .SearchBar {
    display: none;
  }
  .SearchIcon {
    display: inline-block;
  }

  .Header {
    align-items: flex-start;
    padding: 0.5em;
    align-items: center;
  }
  .Title {
    font-size: 12px;
    margin-top: 0;

    & h2 {
      font-size: 11px;
      margin-top: 0;
    }
  }
  .Left,
  .Icons {
    width: 25%;
  }
  .SavedListIcon {
    margin: 0;
  }
}

@media only screen and (min-width: $breakpoint--mobile) {
  .Title h1 {
    font-size: 26px;
  }
  .Bookmark,
  .SearchBar {
    display: inline-block;
  }
  .SearchIcon,
  .MenuIcon {
    display: none;
  }
}

.ProfileMenu {
  display: flex;
  position: relative;
  font-size: 14px;
  & nav {
    position: absolute;
    top: 100%;
    left: -450%;
    z-index: 3;
    width: 12em;
    background: #fcfefe;
    box-sizing: border-box;
    box-shadow: 0 7px 12px 0 rgba(0, 0, 0, 0.04), inset 0 -1px 0 0 #e8e8e8;
    border-radius: 4px;
    & ul {
      list-style: none;
      padding: 0;
      margin: 0;
    }
    & a,
    & button {
      padding: 0.5em 1em;
      display: block;
      text-decoration: none;
      @include font-heading--regular;
      letter-spacing: 0.5px;
      line-height: 16px;
    }
    & li:hover {
      background: #eee;
      & a,
      & button {
        font-weight: bold;
      }
    }
  }
}

.HomeLink {
  text-decoration: none;
  color: black;
  & h1 {
    @include font-heading--medium;
    font-weight: 500;
  }
}
