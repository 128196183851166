@import "../Shared/vars.scss";

.CommentInput {
  display: flex;
  width: 100%;
  padding: 0.75em 1em;
  background: $background--gray;
  align-items: center;
  box-sizing: border-box;
}

.CommentInput input {
  height: 2em;
  width: stretch;
  background: rgba(0, 0, 0, 0);
  border: 0;
  border-bottom: 1px solid black;
}

.Icon {
  border-radius: 50%;
  background-color: white;
  color: black;
  width: 3em;
  height: 3em;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-left: 1em;
  border: 0;
  padding: 0;
  aspect-ratio: 1/1;
}

@media only screen and (min-width: 768px) {
  .CommentInput {
    display: flex;
  }
}
