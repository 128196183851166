@import "../Shared/vars.scss";

.SizePicker {
  list-style: none;
  display: grid;
  grid-column-gap: 1em;
  grid-template-columns: repeat(5, 1fr);
  padding: 0;
}

.Size {
  width: 4em;
  height: 3em;
  display: flex;
  align-items: center;
  justify-content: center;
  color: black;
  background-color: white;
  border: 0;
  text-transform: uppercase;

  border: 1px solid;
}

.SelectedSize {
  @extend .Size;
  color: white;
  background-color: black;
}

.SizePickerContainer {
  background: #f8f8f8;
  padding: 0.5em 1em;
}

@media only screen and (min-width: 768px) {
  .SizePicker {
    display: flex;
  }
}

.DropNumber {
  @include small-caps;
  font-weight: 400;
}
