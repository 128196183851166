@import "../Shared/vars.scss";

$container-width: 400px;
$searchbar-height: 40px;

$search-icon-column-width: calc(16px + 0.35em);
$right-padding: 1em;

@media screen and (max-width: 500px) {
  .SearchBar,
  .SearchBarExpanded {
    width: 100%;

    & .Input {
      box-shadow: 0 7px 12px 0 rgba(0, 0, 0, 0.04), inset 0 -1px 0 0 #e8e8e8;
      padding: 0.5em 1em;
    }
  }

  .ContainerClosed {
    display: none;
  }
  .ContainerOpen {
    z-index: 3;
    position: fixed;
    height: 100%;
    width: 100%;
    display: block;
    top: 0;
    left: 0;
    box-sizing: border-box;
    background-color: white;
  }

  .SearchResultsContainer {
    flex-direction: column;
    padding: 1em 0;
  }

  .MobileHeader {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 1em;

    & > *,
    & button {
      font-size: 9px;
      text-transform: uppercase;
      width: 33%;
      letter-spacing: 1px;
      @include font-heading--regular;
    }
    & > *:first-child {
      text-align: left;
    }
    & button {
      display: flex;
      justify-content: space-between;
      align-items: center;
      min-width: 8em;
    }
    & > *:nth-child(2) {
      text-align: center;
      align-items: flex-start;
    }
  }
}

.SearchBarExpanded {
  @extend .SearchBar;
  z-index: 2;
}

@media screen and (min-width: 500px) {
  .MobileHeader {
    display: none;
  }
  .SearchBar {
    display: block;
    position: relative;
    padding-bottom: 0.25em;
  }
  .SearchBarExpanded {
    height: 240px;
    position: absolute;
    top: -20px;
  }
}

.Input {
  display: flex;
  width: 100%;
  box-sizing: border-box;
  align-items: center;
}

.SearchBar svg {
  color: $text--black;
  margin-right: 0.35em;
  padding: 0.25em;
  opacity: 0.8;
  &:first-of-type {
    border: 1px solid;
    border-radius: 50%;
  }
}

.SearchBar input {
  @include input;
  margin-bottom: 0;
}

// ------------------------

.SearchResultsContainer {
  display: flex;
  box-sizing: border-box;
  background: white;
  width: 100%;
  padding: 1em;
}

.SearchResultsContainer > * {
  margin-bottom: 1em;
}

.SearchResultsContainer ul {
  list-style: none;
  padding: 0;
}

.SearchResults {
  @include font-body--regular;
  font-size: 13px;
}

.SearchResults ul {
  margin: 0;
}

// ------------------------

.Product {
  margin-bottom: 1em;
  padding-bottom: 1em;
  border-bottom: 1px solid $background--gray;
  color: black;
  grid-template-columns: 1fr 4fr;
  grid-column-gap: 1em;
  display: grid;
  text-decoration: none;
}
.Product img {
  height: 70px;
}
.Product > div {
  display: flex;
  flex-direction: column;
  justify-content: space-around;
}

.Price {
  @include font-body--semi-bold;
  font-size: 1em;
}

.Title {
  @include font-heading--medium;
  text-transform: uppercase;
  font-size: 11px;
}

// ------------------------

.Suggestions h3 {
  @include font-heading--medium;
  margin: 0 0 0.4em 0;
  font-size: 10px;
  text-transform: uppercase;
  font-weight: 400;
  opacity: 0.7;
  letter-spacing: 1.47px;
  line-height: 13px;
}

.Suggestions {
  display: flex;
  flex-direction: column;
  margin: 0 0 1.5em 0;
  width: 14em;
  & ul {
    margin: 0;
  }
}

.Suggestions button {
  @include font-body--semi-bold;
  text-decoration: none;
  color: $text--black;
  font-size: 0.8em;
  padding: 0.5em;
}
