@import "../Shared/vars.scss";

.AdminApplicationList {
    display: flex;
}

@media only screen and (min-width: 768px) {
    .AdminApplicationList {
        display: flex;
    }
}

