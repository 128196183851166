@import "Shared/vars.scss";

.InputAndErrorWrapper {
  display: flex;
  flex-direction: column;
  align-items: flex-end;

  color: $dark-gray;
  font-weight: bold;

  & i {
    margin-right: 0.25rem;
  }

  & input {
    width: stretch;
  }

  & span {
    margin-top: 0.5em;
    margin-bottom: 1em;
  }
}
