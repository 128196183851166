@import "../Shared/vars.scss";

.Accordion {
  display: flex;
}

@media only screen and (min-width: 768px) {
  .Accordion {
    display: flex;
  }
}

$gray--open: #e3e3e3;
$gray--closed: #f2f2f2;

.AccordionSection {
  background-color: $gray--closed;
  padding: 1em;
}

.AccordionSectionOpen {
  @extend .AccordionSection;
  background-color: $gray--open;
}

.AccordionSection header {
  @include font-heading--medium;
  text-transform: uppercase;
  display: flex;
  justify-content: space-between;
  font-size: 12px;
  align-items: center;
}

.AccordionSection main {
  font-size: 14px;
}

.AccordionSection a {
  color: black;
  // letter-spacing: 1px;
  // text-transform: uppercase;
  // @include font-heading--medium;
  // font-size: 10px;
}
