@import "Shared/vars.scss";

:local(.CardStatus) {
  position: absolute;
  top: 0;
  right: 0;
  border: 2px solid;
  padding: 0.5em;
  // border-radius: $border-radius;
}
