@import "../Shared/vars.scss";

.Banner {
  display: none;
}

@media only screen and (min-width: 768px) {
  .Banner {
    display: flex;
    width: 100%;
    background-color: black;
    color: white;
    align-items: center;
    justify-content: center;
    margin-bottom: 0.4em;

    & p {
      margin: 0.5em;
      font-size: 0.9em;
      line-height: 20px;
      opacity: 0.85;
      text-align: center;
    }
  }
}
