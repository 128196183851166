@import "../Shared/vars.scss";

.Select,
.Select main {
  width: 100%;
}

// .Select main > * {
//   width: 50%;
// }

.Select * {
  @include font-body--semi-bold;
}

.Select select {
  border-radius: 0;
  border: 0;
  border-bottom: 1px solid;
  padding: 0;
  margin: 0;
  width: 100%;
  color: gray;
  font-weight: 500;
}

.ButtonContainer {
  width: 100%;
}

.Input {
  @include input;
}

.InputAndErrorWrapper {
  display: flex;
  // flex-direction: column-reverse;
  align-items: center;
  width: 100%;

  & > * {
    width: 100%;
  }

  @media screen and (min-width: 768px) {
    justify-content: space-between;
    flex-direction: row;
  }
}

.TwoColErrorWrapper {
  justify-content: space-between;
  flex-direction: row;
  display: flex;
  align-items: baseline;
  & > * {
    width: 50%;
  }
}

.InputError {
  @extend .Input;
  color: $error-red;
  border-bottom: 1px solid $error-red;
  margin-bottom: 0;
  &::placeholder {
    color: $error-red;
  }
}

.FormError {
  @extend .ErrorBox;
  margin: 1.5em 2em 0.5em 2em;

  @media screen and (min-width: 768px) {
    margin: 0 0 0 1em;
  }
}
