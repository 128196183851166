@import "../Shared/styles.scss";

.Login {
  @extend .GrayPageContainer;
}

.Login form {
  margin-bottom: 1em;
  border-bottom: 1px solid rgba(0, 0, 0, 0.2);
}

.Login fieldset {
  border: 0;
  padding: 1em 0;
}

.Login input {
  @include input;
}

.Login button {
  width: 100%;
}

.Login span,
.Login a {
  font-size: 11px;
}

.LoginHeader {
  @extend .TabHeader;
  margin-bottom: 0;
}

.Reset {
  @extend .Column;
  align-items: center;

  & a {
    text-transform: uppercase;
    text-decoration: none;
    font-weight: bold;
    padding: 0.25em 0;
    font-size: 10px;
  }
}
