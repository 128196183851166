@import "../Shared/vars.scss";

.Returns {
  text-align: center;
  max-width: 960px;
  @media screen and (min-width: 960px) {
    margin: 0 auto;
  }
  margin: 1em;
  & h1,
  & h2 {
    text-transform: uppercase;
    text-align: center;
    @include font-heading--medium;
  }
  & h1 {
    font-size: 2em;
  }

  & li {
    width: fit-content;
    margin: 0 auto;
  }

  // & p,
  // & ul {
  //   text-align: left;
  // }
}
