@import "../Shared/vars.scss";

.Countdown {
  font-weight: 300;
  display: flex;
  text-transform: uppercase;
  display: flex;
  flex-direction: column;
  align-items: center;
  position: absolute;
  bottom: 0;
  background-color: white;
  padding: 0.5em 1em;
}

.Countdown header {
  @include font-body--semi-bold;
  font-size: 11px;
  margin-bottom: 0.5em;
  letter-spacing: 1px;
}
.Countdown main {
  @include font-body--regular;
  display: flex;
}

.CountdownItem {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin: 0 0.5em;
  & > *:first-child {
    font-size: 20px;
  }
  & > *:nth-child(2) {
    font-size: 11px;
  }
}

@media only screen and (min-width: 768px) {
  .Countdown {
    display: flex;
  }
}
