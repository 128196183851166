@import "../Shared/vars.scss";

.RelatedPosts {
  box-sizing: border-box;
  margin-bottom: 3em;

  & a {
    color: black;
    text-decoration: none;
  }
}

.RelatedPosts > main {
  overflow-x: scroll;
  width: 100vw;
}

.RelatedPosts > header {
  @include font-heading--regular;
  justify-content: center;
  display: flex;
  flex-direction: column;
  align-items: center;
}

@media only screen and (max-width: 768px) {
  .RelatedPosts main {
    display: flex;
    flex-direction: column;

    & .Image {
      width: 100%;
      height: 400px;
      object-fit: cover;
    }
  }
}

@media only screen and (min-width: 768px) {
  .RelatedPosts main {
    margin: 0;
  }
  .RelatedPosts > main {
    overflow-x: initial;
    width: 100%;
  }

  .RelatedPosts .Image {
    width: 100%;
    height: 400px;
    object-fit: cover;
  }
}
