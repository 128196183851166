@import "../Shared/vars.scss";

.CheckBox {
  border: 1px solid;
  height: 1.25em;
  width: 1.25em;
  margin: 0 0.75em 0 0;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: $text--black;
  & svg {
    color: white;
  }
}

.EmptyCheckBox {
  @extend .CheckBox;
  background-color: white;
}

.CheckBoxLabel {
  @include font-body--semi-bold;
  font-size: 11px;
  color: #4b5157;
  display: flex;
  align-items: flex-start;
  & input {
    display: none;
  }
}
