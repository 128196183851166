@import "../Shared/vars.scss";

.Container {
  display: flex;
  position: relative;
}

.ShareButton {
  display: flex;
  align-items: center;
  & > svg {
    margin-right: 0.5em;
  }
  font-size: 10px;
}

.ShareMenu {
  position: absolute;
  bottom: -2.5em;
  display: flex;
  align-items: center;
  height: 2em;
  right: 0;
  background: white;
  box-shadow: 1px 1px 1px rgba(0, 0, 0, 0.05);
  //   border-top: 1px solid $gray;

  & > div {
    margin: 0 0.25em;
  }
}
