@import "../Shared/vars.scss";

@media screen and (max-width: 600px) {
  .ProductCard {
    width: 45%;
    margin-left: 2.5%;
    margin-right: 2.5%;
    margin-top: 1em;
  }
  .ProductCard main img {
    height: 43vw;
    width: 43vw;
    object-fit: cover;
  }
}

.ProductCardLargeMobile {
  @extend .ProductCard;
}
.ProductCardLargeMobile {
  width: 100%;
  margin-left: 0;
  margin-right: 0;
}

.ProductCardLargeMobile main img {
  height: 350px;
  width: 100%;
}

@media screen and (min-width: 600px) {
  .ProductCard main img {
    width: 100%;
    object-fit: cover;
    height: 400px;
    z-index: 0;
  }
}

.ProductCard {
  padding: 0;
  & :hover {
    .ViewItemButton {
      display: flex;
      position: absolute;
      top: 47.5%;
    }
  }

  & .Bookmark {
    position: absolute;
    top: 0.3em;
    right: 0.3em;
    height: 2em;
    width: 2em;
  }
}

.ProductCard header {
  width: 100%;
  text-transform: uppercase;
  padding: 0.25em 0;

  & img {
    height: 1.75em;
    width: 1.75em;
    border-radius: 50%;
    object-fit: cover;
  }

  & a {
    background-color: white;
    color: black;
    text-decoration: none;
    display: flex;
    align-items: center;
    overflow: hidden;
  }
}

.ProductCard main {
  width: 100%;
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;

  .ViewItemButton {
    @extend .ButtonPrimary;
    display: none;
    z-index: 1;
    letter-spacing: 2px;
    border: 0;
    padding: 0.75em 1em;
    @media screen and (min-width: 768px) {
      padding: 1.5em 3em;
    }
    width: fit-content;
    margin: 0 auto;
    text-decoration: none;
  }
}

// .Bookmark {
//   position: absolute;
//   top: 1em;
//   right: 1em;
//   border-radius: 50%;
//   padding: 0.5em;
//   display: flex;
//   justify-content: center;
//   align-items: center;
// }

// .BookmarkSaved {
//   @extend .Bookmark;
//   background: $main--green;
//   color: white;
// }
// .BookmarkEmpty {
//   @extend .Bookmark;
//   background-color: white;
//   color: $text--black;
// }

// footer

.Slider {
  background: lightgray;
  margin: 0.5em 0;
  border-radius: 5px;
  display: flex;
  align-items: center;
  justify-self: flex-start;
}
.Background {
  border: 1px solid $placeholder--gray;
  border-radius: 5px;
  width: 100%;
  z-index: 0;
}
.Foreground {
  border: 1px solid $main--green;
  z-index: 1;
}

.ProductCard footer {
  padding: 0.5em 0;
}

.ProductCard footer > * {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.Title {
  @include font-heading--regular;
  text-transform: uppercase;
  font-size: 0.6em;
  color: $text--black;
  margin-top: 0.25em;
  font-weight: 600;
  letter-spacing: 0.4px;
  min-height: 2em;
  @media screen and (min-width: 600px) {
    font-size: 0.8em;
  }
}

.ProductCardLargeMobile .Title {
  font-size: 0.8em;
}

.TimeRemaining {
  @extend .SmallDate;
}

.MoveToBagContainer {
  margin-top: 0.5em;
  font-weight: bold;
  & span {
    font-size: 12px;
  }
}
.MoveToBagContainer > *:first-child {
  width: 30%;
  margin-right: 20%;

  & * {
    color: black;
    padding: 0.5em 0;
  }
}

.MoveToBagContainer button {
  @include font-heading--regular;
  & svg {
    background-color: #efefef;
    padding: 0.5em;
    border-radius: 50%;
  }
}

@media screen and (min-width: 800px) {
  .MoveToBagContainer > *:first-child {
    width: 25%;
    margin-right: 25%;
    min-width: 100px;
    & * {
      background: transparent;
    }
  }
  .ProductCard select,
  .ProductCard button {
    font-size: 14px;
  }
}

.MoveToBagContainer > * {
  display: flex;
  align-items: baseline;
  box-sizing: border-box;
}

.MoveToBagContainer button {
  display: flex;
  align-items: center;
}

.ButtonWithIcon {
  & svg {
    margin-right: 0.25em;
  }
}
