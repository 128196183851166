@import "../Shared/vars.scss";

.PostCard {
  width: 100%;
  display: flex;
  flex-direction: column;
  position: relative;

  & .PostCardImage {
    max-width: 100%;
    aspect-ratio: 1/1;
    width: 100%;
    object-fit: cover;
  }

  & .PostCardImageWithinGrid {
    @extend .PostCardImage;
    // max-height: 28vw;
  }
}

.PostCardHeader {
  &:hover {
    cursor: pointer;
  }
  display: flex;
  height: 3em;
  align-items: center;
  & img {
    border-radius: 50%;
    height: 2em;
    width: 2em;
    margin-right: 0.75em;
  }
  overflow: hidden;
  @extend .Username;
}
.PostCard > header {
  @extend .Username;
  display: none;
}

.PostCard main {
  display: flex;
  flex-direction: column;
}

.Image {
  width: 100%;
}

.PostCard footer {
  display: none;
}

@media only screen and (min-width: 768px) {
  .PostCard > header {
    display: flex;
    height: 3em;
    align-items: center;
    & img {
      border-radius: 50%;
      height: 2em;
      margin-right: 0.75em;
    }
  }
  .PostCard footer {
    display: flex;
    flex-direction: column;
  }
  .PostCard > img {
    width: 100%;
  }
}
