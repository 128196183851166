@import "../Shared/vars.scss";

.WornByContainer {
  background-color: $light-gray-background;
  padding: 2em 0;
}

.WornBy {
  width: 100%;
  box-sizing: border-box;
  flex-direction: column;

  align-items: center;
  max-width: $content-width;

  & > header {
    margin: 0 auto 2em auto;
  }

  & > *:first-child {
    grid-column: 1 / span 1;
    grid-row: 1 / span 2;
  }
}
@media only screen and (max-width: 768px) {
  .WornBy {
    & a,
    & img {
      max-height: 250px;
    }
  }
}

@media only screen and (min-width: 768px) {
  .WornBy {
    margin: 0em auto;
  }
}

.WornByImages {
  display: grid;
  grid-template-columns: 1fr;
  grid-template-rows: 1fr 1fr 1fr;
  width: 100%;
  box-sizing: border-box;

  & > * {
    display: block;
    position: relative;
    width: 100%;
  }

  & img {
  }
}

@media only screen and (min-width: 768px) {
  .WornBy > * {
    height: 100%;
  }
}

.WornByName {
  position: absolute;
  bottom: 0;
  padding: 1em;
  background: white;
  display: flex;
  flex-direction: column;
  width: 70%;
  left: 15%;
  box-sizing: border-box;
  & div {
    @include small-caps;
    color: black;
    &:first-child {
      font-size: 0.6em;
      color: $text--black;
    }
  }
  & button {
    margin: 1em 0 0 0;
    width: fit-content;
  }
  & .Name {
    font-size: 1em;
  }
}

.WornByImages img {
  height: 100%;
  width: 100%;
  object-fit: cover;
}

@media only screen and (min-width: 768px) {
  .WornByImages {
    grid-template-columns: 1fr 1fr;
    grid-template-rows: 25em 25em;

    & > *:first-child {
      grid-row: 1 / span 2;
    }
    & > *:nth-child(2) {
      grid-column: 2 / span 1;
      grid-row: 1 / span 1;
    }
    & > *:nth-child(3) {
      grid-column: 2 / span 1;
      grid-row: 2 / span 1;
    }
  }

  .WornByName {
    width: 45%;
    left: 0;
  }
}
