@import "./vars.scss";
@import "./buttons.scss";

.button {
  border-radius: 5px;
  border: 0;
  background: $green;
  color: white;
  font-weight: 600;
  padding: 0.25em 0.5em;
  display: inline-block;
  font-size: 0.75em;
  text-decoration: none;
  &.active {
    color: $dark-gray;
  }
}

.button-secondary {
  background: transparent;
  color: $pink;
  text-decoration: underline;
  width: fit-content;
  padding: 0;
  margin: 0;
}

:local(.HomePage) {
  height: 100vh;
  width: 100vw;
  display: flex;
  flex-direction: column;
  // justify-content: center;
  align-items: center;

  & h1 {
    color: $dark-gray;
    font-weight: 300;
    text-transform: uppercase;
    letter-spacing: 0.1em;
  }

  // & label {
  //   background: $pink;
  //   padding: 0.25em 0.5em;
  //   margin: 0.25em;
  //   display: block;
  //   width: 50vw;
  //   font-size: 0.75em;
  //   font-weight: bold;
  //   color: $green;
  // }

  // & input {
  //   width: 50vw;
  //   padding: 0.25em 0.5em;
  //   margin-bottom: 1em;
  // }

  & button {
    font-weight: bold;
    color: $green;
    padding: 1em;
    border: 0;
  }
}

.form {
  display: flex;
  flex-direction: column;

  & input,
  & textarea {
    font-size: 1em;
    padding: 0.5em;
    margin-bottom: 1em;
  }

  button {
    @extend .button;
    font-size: 1.25em;
  }
}

.card {
  & > a {
    display: grid;
    grid-template-columns: 1fr 6fr;
    align-items: center;
    grid-column-gap: 1em;
  }

  // border-radius: $border-radius;
  // box-shadow: 0 4px 1px $medium-gray;
  border: 1px solid $gray;
  padding: 0em;

  font-weight: 500;

  & img {
    height: 10vh;
    width: 10vh;
    border-radius: 5px;
    object-fit: cover;
  }
  margin-bottom: 1em;

  & a {
    color: $green;
  }
}

.accent {
  color: $dark-gray;
  font-weight: 500;
  margin: 1em;
  padding: 1em;
  border: 2px solid $dark-gray;

  & h1 {
    color: $green;
    text-align: center;
  }
  & a {
    font-weight: 600;
    display: block;
    color: $dark-gray;
    font-size: 1.25em;
    text-align: center;
  }
}

.bold--pink {
  color: $pink;
  font-weight: 800;
  margin-bottom: 1em;
}

.small {
  font-size: 0.8em;
}

.accent--pink {
  color: $pink;
  border: 1px solid $pink;
  width: fit-content;
  text-align: center;
  @media only screen and (min-width: 768px) {
    margin-left: auto;
    margin-right: auto;
  }
  margin: 1em;
  font-weight: 500;
  padding: 1em;
  display: block;
}

p.box {
  border: 2px solid $pink;
  width: fit-content;
  text-align: center;
  @media only screen and (min-width: 768px) {
    margin-left: auto;
    margin-right: auto;
  }
  margin: 1em;
  padding: 1em;
  display: block;
}

.Column {
  display: flex;
  flex-direction: column;
}

.GrayPageContainer {
  background: $background--gray;
  padding: 1em;
  @media only screen and (min-width: 768px) {
    padding: 3em;
  }
  & section {
    background: white;
    display: flex;
    justify-content: center;
    align-items: flex-start;
    flex-direction: column;

    & h1 {
      @include font-heading--regular;
      margin: 0;
      font-size: 18px;
      text-transform: uppercase;
      letter-spacing: 1px;
    }
    & main {
      padding: 2em;
      & a {
        color: $main--green;
      }
    }
  }
  display: flex;
  justify-content: center;
  align-items: center;
}

.TabHeader {
  @include font-heading--medium;
  display: flex;
  justify-content: center;
  width: 100%;
  font-size: 11px;
  text-transform: uppercase;
  & a {
    font-weight: bold;
    margin: 0;
    padding: 1.5em 2.5em 0.5em 2.5em;
    border-bottom: 3px solid rgba(0, 0, 0, 0.2);
    text-decoration: none;
    color: black;
    font-size: 12px;
    letter-spacing: 1px;
  }
  & a.Active {
    border-bottom: 3px solid $main--green;
  }
  @media only screen and (min-width: 768px) {
    margin-bottom: 2em;
  }
}
