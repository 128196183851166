@import "../Shared/vars.scss";

.BreadcrumbsAndShare {
  display: flex;
  justify-content: space-between;
  margin: 0;
  padding: 1em;
  box-sizing: border-box;
  border-bottom: 1px solid $background--gray;

  & .Right {
    display: flex;
  }

  & .FiltersAndSortBy {
    display: none;
  }
}

@media only screen and (min-width: 768px) {
  .BreadcrumbsAndShare {
    margin: 0 auto;
    width: 100%;
    max-width: 1200;
    .FiltersAndSortBy {
      display: flex;
    }
  }
}
