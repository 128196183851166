@import "../Shared/vars.scss";

// TODO this is exactly the same as the checkout
//.CheckoutLayoutContainer, .CheckoutLayoutPage?

.Container {
  background: $background--gray;
  padding: 1em;
}

.Page {
  box-sizing: border-box;
  margin: 0;
  & .Left > section,
  aside > section {
    margin-bottom: 1em;
  }
}

.Left {
  margin-top: 0.5em;
}

.ViewSavedList {
  display: none;
}

@media only screen and (min-width: 768px) {
  .Container {
    padding: 0.75em;
  }

  .Page {
    display: flex;
    max-width: 1100px;
    margin: 0 auto;

    & > *:first-child {
      width: 60%;
      margin-right: 2em;
      box-sizing: border-box;
    }
    & > *:nth-child(2) {
      width: 40%;
      box-sizing: border-box;
    }
  }

  .Left {
    display: flex;
    flex-direction: column;
    margin: 0 1em 0 0;
    & > * {
      margin-bottom: 2em;
    }
  }

  .ViewSavedList {
    background: white;
    display: flex;
    align-items: center;
    justify-content: space-around;
    padding: 1em;
    & svg {
      margin: 1em;
      stroke: 2px;
    }
    & > div {
      width: 50%;
    }
    & h1 {
      @include font-heading--regular;
      text-transform: uppercase;
      font-size: 18px;
      margin: 0 1em 0.5em 0;
    }
    & p {
      margin: 0;
      font-size: 12px;
    }
  }
}

.FreeDeliveryMessage {
  border: 1px solid black;
  padding: 0.5em;
  font-size: 11px;
  background: $background--gray;
  margin-bottom: 1em;
  text-align: center;
}

.FreeDeliveryApplies {
  @extend .FreeDeliveryMessage;
}

.Total > div {
  @extend .SpaceBetween;
}

.SmallText {
  color: #656b70;
  @include font-heading--regular;
  font-size: 11px;
  line-height: 20px;
}

.BigPrice {
  @extend .SmallPrice;
  font-size: 16px;
  letter-spacing: 1.2px;
  line-height: 22px;
}

.Total a {
  display: block;
  color: white;
  margin: 1em 0;
  padding: 1em;
  text-align: center;
}
