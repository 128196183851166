@import "../Shared/vars.scss";

.Filters {
  display: flex;
  flex-direction: column;
  height: fit-content;
}

.FiltersExpanded {
  @extend .Filters;
  z-index: 1;
  background-color: white;
  // width: 200%;
}

.FiltersExpanded .Overlay {
  position: fixed;
  height: 100vh;
  width: 100vw;
  top: 0;
  left: 0;
  background: rgba(0, 0, 0, 0.2);
  z-index: 1;
}

.FiltersExpanded header,
.FiltersExpanded main {
  z-index: 2;
}

.FiltersExpanded header {
  width: 100%;
}

@media screen and (min-width: 600px) {
  .Filters {
    padding: 0 1.5em 1em 1.5em;
  }
  .FiltersExpanded {
    padding: 0 1.5em 1em 1.5em;
    box-shadow: 1px 2px 3px $background--gray;
    width: 15em;
    position: relative;
  }

  .Overlay {
    display: none;
  }
}

.Filters header button {
  @include font-heading--medium;
  background-color: white;
  border-radius: 0;
  border: 0;
  padding: 1em;
  text-align: center;
  display: flex;
  align-items: center;
  justify-content: space-around;

  & span {
    margin-right: 0.5em;
  }
}

.Filters > main {
  font-size: 12px;
  display: flex;
  flex-direction: column;
  width: 100%;
  padding: 1em 0.5em;
  box-sizing: border-box;
  background-color: white;
  & label {
    @include font-heading--regular;
    color: $text--black;
    font-size: 12px;
    line-height: 24px;
    margin-bottom: 0.5em;
    display: flex;
    align-items: center;
    & input {
      margin: 0 0.5em 0 0;
      height: 2em;
    }
  }
}

.FilterContainer {
  width: 100%;
}

.FilterContainer * {
  @include font-body--semi-bold;
}

.Filters select {
  border-radius: 0;
  border: 0;
  border-bottom: 1px solid;
  padding-bottom: 0.5em;
  margin: 1em 0 1.5em 0;
  width: 100%;
  color: gray;
  font-weight: 500;
}

.ButtonContainer {
  width: 100%;
}

// & button {
//   @include font-heading--regular;
//   border: 1px solid;
//   width: 100%;
//   margin-bottom: 1em;
//   font-size: 11px;
//   letter-spacing: 1.83px;
//   line-height: 13px;
//   padding: 0.75em 1em;
//   text-transform: uppercase;
// }
