@import "../Shared/vars.scss";

.DesignerSpotlight header {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  & > *:nth-child(2) {
    @extend .SubTitle;
  }
}

.DesignerSpotlight h1,
.DesignerSpotlight h2 {
  @include font-heading--regular;
  text-transform: uppercase;
  font-size: 26px;
  letter-spacing: 2px;
  line-height: 31px;
  color: black;
  text-align: center;
  font-weight: 700;
  @media screen and (max-width: 600px) {
    font-size: 16px;
    margin-bottom: 0.5em;
  }
}

@media screen and (max-width: 600px) {
  .DesignerSpotlight h2 {
    margin-bottom: 1em;
  }
}

.DesignerSpotlight header h1 {
  text-transform: uppercase;
}

.DesignerSpotlight {
  margin: 1em;
}
.DesignerSpotlight main {
  display: flex;
  flex-direction: column-reverse;
  margin: 0 1.5em;
}

.DesignerSpotlight .Left {
  display: flex;
  flex-direction: column;
  @media screen and (min-width: 768px) {
    max-width: 320px;
  }
  padding: 1em;
  background: $background--gray;

  & > h1 {
    text-transform: none;
    font-weight: 100;
    margin: 1rem 0;
    font-size: 16px;
    letter-spacing: -0.35px;
    line-height: 20px;
    margin-bottom: 0;
    text-align: left;
  }

  & img {
    border-radius: 100%;
    aspect-ratio: 1/1;
    max-width: 8em;
    height: 8em;
    width: 8em;
    object-fit: cover;
  }

  & p {
    font-size: 12px;
    margin-bottom: 2em;
    line-height: 18px;
  }

  & a {
    width: fit-content;
  }
}

.DesignerSpotlight .Right {
  background: #e9e9e9;
}
.DesignerSpotlight .Right img {
  width: 100%;
  height: 100%;
  object-fit: cover;
}

@media only screen and (min-width: 768px) {
  .DesignerSpotlight {
    max-width: 70%;
    margin: 4em auto;
    overflow: hidden;
  }
  .DesignerSpotlight > header {
    width: 100%;
    margin: 1em auto 2em auto;
  }

  .DesignerSpotlight > main {
    display: grid;
    grid-template-columns: 4fr 8fr;
  }
  .DesignerSpotlight .Right img {
    max-height: 600px;
  }
  .DesignerSpotlight .Left {
    background: white;
    padding: 0 2em;
    & img {
      max-width: 12em;
      max-height: 12em;
      margin-bottom: 1em;
    }
    & > h1 {
      font-size: 36px;
      line-height: 40px;
      letter-spacing: -2px;
    }
    & p {
      font-size: 14px;
    }
  }
}
