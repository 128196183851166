@import "Shared/vars.scss";
.NewProduct {
  background: $gray-background;
  padding: 1em;
  @media screen and (min-width: 768px) {
    padding: 2em 22.5%;
  }
  box-sizing: border-box;

  & img {
    object-fit: contain;
    width: 100%;
    border-radius: $border-radius;
    box-shadow: $box-shadow;
  }

  & label {
    margin-top: 1em;
  }
  & input[type="file"] {
    display: none;
  }

  & fieldset {
    border: 0;
    margin: 0;
  }

  & input,
  & textarea {
    @include input;
  }

  & h1 {
    @include font-heading--regular;
  }
}

.WarningText {
  padding: 0.5em;
  margin-top: 2em;

  & header {
    font-weight: bold;
    color: darkred;
    letter-spacing: 1px;
    font-size: 1.2em;
    border: 1px solid darkred;
    padding: 0.25em 0.5em;
    border-radius: $border-radius;
    width: fit-content;
  }

  & ul {
    padding-left: 0;
    color: $pink;
    font-weight: 500;
    font-size: 1.2em;

    & li {
      margin-bottom: 0.5em;
    }
  }
}
