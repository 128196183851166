@import "../Shared/vars.scss";

.LandingPage {
  margin: 0;

  & > *:first-child {
    width: 100%;
    padding: 0;
    top: 0;
  }
}

.TextBlock {
  background: $gray-background;
  margin-bottom: 1em;
  padding: 2em 1em;
}

.Block {
  display: flex;
  flex-direction: column;
  margin-bottom: 4em;
  align-items: center;
  justify-content: flex-start;

  & > h2 {
    margin: 1em;
    @media screen and (min-width: 768px) {
      margin: 0;
    }
    height: 2em;
    font-weight: bold;
  }
  & p {
    margin: 2em;
    text-align: center;
    height: 5em;
  }
}

/////////
.LearnMore {
  margin: 3em 0;
}
.LearnMore h2 {
  @include font-heading--regular;
  text-transform: uppercase;
  letter-spacing: 1px;
  font-size: 16px;
  margin-top: 1rem;
  text-align: center;
}
.LearnMore p {
  margin-bottom: 1rem;
  font-size: 14px;
}
.LearnMore img {
  object-fit: cover;
  width: 90vw;
  height: 25em;
  @media only screen and (min-width: 768px) {
    width: 100%;
  }
}
@media only screen and (min-width: 760px) {
  .LearnMore {
    display: grid;
    grid-template-columns: 1fr 1fr 1fr;
    grid-column-gap: 2em;
    max-width: $content-width;
    margin: 6em auto 0 auto;
  }
}

@media only screen and (min-width: 760px) {
  .LandingPage {
    margin: 0 auto;
  }

  .MenuIcon {
    display: none;
  }
  .Nav {
    display: flex;
    justify-content: space-between;
    margin-bottom: -3em;

    & ul {
      display: flex;
      justify-content: space-between;
      list-style: none;
      padding: 0;
      width: 25%;

      & a {
        text-transform: uppercase;
        color: #333;
        font-weight: 500;
        text-decoration: none;
        font-size: 0.8em;
        letter-spacing: 2px;
      }
    }
  }

  .TextBlock {
    height: 18em;
    flex-direction: column;
    justify-content: flex-start;
  }

  .StarPicksGrid {
    width: 100%;
    display: grid;
    grid-template-columns: 1fr 1fr 1fr;
    grid-column-gap: 1em;
    grid-row-gap: 2em;
  }

  .CarouselContainer {
    display: none;
  }
}

.Spiel {
  text-align: center;
  font-size: 1em;
  margin: 2em auto 3em auto;
  max-width: 320px;
  padding-bottom: 2em;
  @media only screen and (min-width: 768px) {
    max-width: 700px;
    margin: 2em auto 3em auto;
  }
  border-bottom: 1px solid $gray-background;
}

.Heading {
  @include font-heading--regular;
  color: #000000;
  font-size: 20px;
  letter-spacing: 4.47px;
  line-height: 31px;
  width: 633px;
  text-align: center;
}

.Hero {
  width: 100%;
  display: block;
}
.HeroCopy {
  display: flex;
  flex-direction: column;
  background: transparent;
  align-items: center;
  text-align: center;
  padding: 1em 2em;
  & h4 {
    @include small-caps;
    text-align: center;
    font-size: 12px;
  }
  & h1 {
    @include font-heading--regular;
    font-size: 21px;
    line-height: 25px;
  }
  & p {
    margin: 0;
  }
  & a {
    margin: 2em 0;
  }
}

@media only screen and (min-width: 768px) {
  .HeroContainer {
    position: relative;
    display: flex;
    flex-direction: row-reverse;

    & img {
      width: 50%;
      box-sizing: border-box;
      max-height: 600px;
      object-fit: cover;
      object-position: right;
    }
  }
  .HeroCopy {
    padding: 2em;
    max-width: 600px;
    & > * {
      text-align: left;
    }
    align-items: flex-start;
    & h4 {
      margin-top: 0;
      text-align: left;
    }
    & h1 {
      font-size: 40px;
      line-height: 45px;
      margin: 1rem 0;
    }
    & a {
      margin-bottom: 0;
    }
  }

  .Hero {
    width: 100%;
    object-fit: cover;
  }
}

.Strapline {
  font-weight: bold;
  text-transform: uppercase;
  font-size: 0.8em;
  margin: 2.5em 0 2em 0;
  @media only screen and (min-width: 768px) {
    margin: 2.5em auto 1.5em auto;
    font-size: 1.2rem;
  }
  width: fit-content;
  text-align: center;
}

.ShopNow {
  font-size: 1.25em;
  border: 1px solid;
  padding: 0.75rem 1rem;
  display: inline-block;
  margin-top: -1.5em;
  font-weight: bold;
  color: #0a3030;
  background: white;
  text-decoration: none;
  text-transform: uppercase;
  letter-spacing: 2px;
}

.HeroContainer {
  display: flex;
  @media only screen and (max-width: 768px) {
    flex-direction: column;
  }

  align-items: center;
  min-height: 400px;
  background: #f2f2f2;
}

.Socials {
  margin-left: -5px;
  margin-top: 4px;

  & > *:nth-child(2) {
    margin-left: -2px;
  }
  @media only screen and (min-width: 768px) {
    & > *:nth-child(2) {
      margin-left: -5px;
    }
    margin-left: -8px;
  }
}

.InfoContainer {
  background: $gray-background;
  padding: 1em;
  margin: 2em 0;
}

.Info {
  display: grid;
  grid-template-columns: 1fr;
  font-size: 14px;
}
.Info section {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 2em 1em;
}
.Info section > * {
  text-align: center;
  line-height: 1.6;
}
.Info h2 {
  @include small-caps;
}
.Info svg {
  margin-bottom: 1em;
}

@media only screen and (min-width: 768px) {
  .Info {
    max-width: $content-width;
    margin: 2em auto;
    grid-template-columns: 1fr 1fr 1fr;
  }

  .Info main {
    height: 6em;
  }
}
