@import "../Shared/vars.scss";

.Breadcrumbs {
  display: flex;
  align-items: center;
  list-style: none;
  margin: 0;
  padding: 0;

  & li {
    margin-right: 0.375em;
  }
  & a {
    border-bottom: 1px solid;
    text-decoration: none;
    color: #262d33;
  }
  & li,
  & a {
    color: #262d33;
    opacity: 0.85;
    font-size: 12px;
  }
}

@media only screen and (min-width: 768px) {
  .Breadcrumbs {
    display: flex;
  }
}
