@import "Shared/styles.scss";

.Camera {
  & img {
    max-width: 100%;
    box-shadow: 1px 1px 4px $medium-gray;
    margin: 1em 0;
  }
  display: flex;
  flex-direction: column;
  justify-content: center;
  max-width: 800px;
  margin: auto;
  & button.primary {
    width: fit-content;
    align-self: center;
  }

  & fieldset {
    border: 0;
    margin: 0;
  }

  & input {
    margin: 1em 0;
    padding: 1em;
    font-size: 1.2em;
    font-family: "Quicksand";
    width: stretch;
    border: 1px solid $medium-gray;
  }
}

.ImageContainer {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  // border: 2px solid black;
  // border-radius: 20px;

  & img {
    max-width: 45%;
    margin: 2em 2%;
  }
}

.ChooseImage {
  display: flex;
  flex-direction: column;
  display: none;
}

.EmptyUpload {
  display: flex;
  justify-content: center;
  align-items: center;
  width: stretch;
  color: $green;
  border: 2px solid $green;
  height: 30vh;
  margin: 0 0 2em 0;
  flex-direction: column;
  box-shadow: 1px 1px 4px $gray;

  & h1 {
    color: $green;
    margin-top: 0;
  }
  & i {
  }
}

.PhotoUploadContainer {
  display: flex;
  flex-direction: column;
  align-items: center;
}
