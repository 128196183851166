@import "../Shared/vars.scss";

.MailingList {
  @include font-heading--regular;
  background-color: $background--gray;
  display: flex;
  padding: 1em 0;
  height: 100%;
}

.MailingList main {
  display: flex;
  align-items: center;
  position: relative;
}

.MailingList label {
  position: absolute;
  top: -1.8em;
  font-size: 0.7em;
  letter-spacing: 0.5px;
  color: #333;
}

.MailingList h1 {
  text-transform: uppercase;
  font-size: 14px;
  text-align: left;
  margin: 0 0 1em 0;
  letter-spacing: 2px;
}

.MailingList input {
  @include font-body--regular;
  padding: 0.5em;
  height: 100%;
  box-sizing: border-box;
  border: 0;
}
.MailingList label {
  @include font-body--regular;
  font-size: 0.8em;
  color: black;
}

.MailingList button {
  border: 1px solid $main--green;
  @include font-heading--medium;
  text-transform: uppercase;
  font-size: 0.7em;
  letter-spacing: 0.6px;
  color: $main--green;
  border-radius: 2px;
  background-color: $background--gray;
  height: 100%;
  box-sizing: border-box;
  margin: 0 2em;
}

@media screen and (max-width: 860px) {
  .MailingList {
    flex-direction: column;
    padding: 2.5em 2em;
    align-items: center;
    width: 100%;
    box-sizing: border-box;
    position: relative;
    padding: 1em;
    & > * {
      width: 100%;
      margin: 0;
    }
  }
  .MailingList * {
    width: 100%;
  }
  .MailingList header {
    margin: 0;
    width: 100%;
    display: flex;
    justify-content: flex-start;
  }
  .MailingList main {
    margin: 2em 0 0 0;
    flex-direction: column;
    align-items: flex-start;
  }
  .MailingList button {
    margin: 2em 0 0 0;
  }
}

@media screen and (min-width: 860px) {
  .MailingList {
    justify-content: center;
    align-items: center;
  }
  .MailingList main {
    margin: 3em;
  }
  .MailingList header {
    width: 20em;
  }
  .MailingList input {
    width: 15em;
  }
  .MailingList button {
    width: 15em;
  }
}

.MailingList svg {
  color: $main--green;
  @media screen and (min-width: 860px) {
    margin-right: 2rem;
  }
}

.Submitted {
  @include font-body--regular;
}
