@import "../Shared/vars.scss";

.ProductPage {
  display: flex;
  flex-direction: column;
}

.ProductPage img {
  width: 100%;
  object-fit: contain;
  object-position: top;
}

.ImageContainer {
  position: relative;
  display: flex;
  justify-content: center;
  height: fit-content;
}

.Row {
  margin: 2em 1em;
}

.ViewProfile {
  @include font-heading--medium;
  text-transform: uppercase;
  width: 100%;
  display: flex;
  align-items: center;
  padding: 1em;
  box-sizing: border-box;
  background: #f2f2f2;
  font-size: 12px;
  letter-spacing: 0.91px;

  & img {
    border-radius: 50%;
    height: 3em;
    width: 3em;
    margin-right: 0.5em;
  }

  & > *:last-child {
    margin-left: auto;
  }
}

.BlankLink {
  display: flex;
  align-items: center;
  text-decoration: none;
  color: black;
  @include font-heading--medium;
  letter-spacing: 1px;
}

.ProductPage .MoreImages {
  display: none;
  // width: 100%;
  // display: flex;
  // justify-content: flex-start;
  // padding: 0;
  // box-sizing: border-box;
  // align-items: flex-start;
  // overflow-y: hidden;

  // & img {
  //   height: 100%;
  //   max-height: 20vw;
  //   object-fit: cover;
  //   width: 20%;
  //   aspect-ratio: 1/1;
  // }
}
.DesktopImageContainer {
  display: none;
}
.Carousel {
  display: initial;
}
.CarouselImage {
  width: 100vw;
  height: 100%;
  // object-fit: cover;
}

@media only screen and (min-width: 768px) {
  .Carousel {
    display: none;
  }
  .DesktopImageContainer {
    display: initial;
  }
  .ProductPage {
    @include Layout;
  }
  .ProductPage > main {
    display: grid;
    grid-template-columns: 1fr 1fr;
    grid-column-gap: 1em;
    margin-bottom: 5em;
  }
  .ProductPage > main > .Right {
    grid-column: 2 / span 1;
    grid-row: 1;
  }
  .ProductPage .MoreImages {
    display: initial;
    margin-top: 1em;
    width: 100%;
    grid-column: 1 / span 1;
    height: 9em;
    display: flex;
    padding: 0;
    box-sizing: border-box;
    align-items: flex-start;
    overflow-y: hidden;
    & img {
      height: 100%;
      object-fit: cover;
      width: 20%;
      box-sizing: border-box;
    }
    & img:nth-child(n + 2) {
      margin-left: 10px;
    }
  }
  .MoreImagesActive {
    border: 1px solid black;
  }

  .Buttons {
    display: flex;
    margin: 1em 0;
    justify-content: space-between;
  }
}

.Title {
  @include font-heading--medium;
  color: #000000;
  font-size: 18px;
  line-height: 22px;
  @media only screen and (min-width: 768px) {
    font-size: 37px;
    line-height: 60px;
  }
  text-align: left;
  text-transform: uppercase;
}

.Buttons {
  display: flex;
  margin: 2em;
  flex-direction: column;
  & > * {
    width: 100%;
  }

  & > *:first-child {
    margin-bottom: 1em;
  }
  align-items: center;
}

.AdminOptions {
  padding: 1em;
  border: 2px solid black;
  margin-bottom: 1em;
  @media screen and (max-width: 700px) {
    display: flex;
    flex-direction: column;
  }
  & .ErrorBox,
  & .GreenBox {
    margin-bottom: 1em;
  }

  & button,
  & a {
    width: fit-content;
    margin: 0 0.5em 0.5em 0;
  }
}

.BigPrice {
  @extend .Price;
  font-size: 1.25em;
  margin-top: 1rem;
}

.PriceCopy {
  color: #656b70;
  font-family: Quicksand;
  font-size: 13px;
  line-height: 16px;
  text-align: left;
  font-weight: 600;
  margin-top: 0.25em;
}
