@import "Shared/styles.scss";

.Publish {
  & h1,
  h2 {
    text-align: center;
  }

  & img {
    width: 80%;
    object-fit: contain;
    display: block;
    margin: 1em auto;
  }

  & input {
    font-size: 1.25em;
    padding: 0.5em;
    margin: 1em 0;
  }

  & main {
    display: flex;
    flex-direction: column;
    padding: 0 1em;
  }

  & .CheckboxLabel {
    display: flex;
    align-items: center;

    & input {
      margin-left: 0.5em;
      width: 1em;
    }
  }

  & footer {
    display: flex;
    justify-content: space-around;
  }

  & label {
    margin-right: 2em;
    margin-bottom: 1em;
    padding: 0 1em;
    border-radius: 8px;
  }

  .Sizes {
    margin-bottom: 2em;
  }
}

.Buttons {
  display: flex;
  justify-content: space-evenly;
  margin-top: 1em;
  & button {
    padding: 2em;
  }
}

@media only screen and (min-width: 700px) {
  .Publish {
    max-width: 700px;
    margin: 0 auto;

    & img {
      // max-height: 50vh;
    }
  }

  .Sizes {
    display: flex;
    justify-content: space-around;
  }
}

.CheckboxLabelContainer {
  display: flex;
  flex-wrap: wrap;
}

.MultiplePhotoContainer {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;

  & img {
    border-radius: 5px;
    margin: 2%;
    width: 45%;
  }
}
