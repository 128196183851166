@import "../Shared/vars.scss";

.BagItem {
  display: flex;
  // align-items: flex-end;
  border-bottom: 1px solid $gray;
  margin-bottom: 1em;
  padding-bottom: 1em;
  position: relative;
  max-height: 120px;
}

.BagItem img {
  max-width: 120px;
  object-fit: cover;
  margin-right: 1em;
}

@media only screen and (min-width: 768px) {
  .BagItem {
    display: flex;
  }
  .Inputs label {
    margin-right: 1em;
  }
  .Inputs label span:first-of-type {
    margin-bottom: 0.5em;
    margin-top: 0.5em;
  }
}

.Middle {
  display: grid;
  grid-template-rows: 1fr 1fr 1fr 1fr;
  box-sizing: border-box;
  & h3 {
    margin: 0;
    text-transform: uppercase;
    font-size: 1em;
  }
}

.Inputs {
  display: flex;
  flex-direction: row;
  align-items: flex-end;
  & input,
  & select {
    margin: 0 1em 0 0;
  }
}

.Inputs label {
  @include font-body--semi-bold;
  display: flex;
  flex-direction: column;
}
.Buttons {
  display: flex;
  align-items: end;
  margin-left: auto;
}
.Buttons button {
  @include font-body--regular;
  // background-color: white;
  border: 0;
  border-radius: 3px;
  display: flex;
  align-items: center;
  font-size: 0.8em;
  justify-content: space-between;

  & div {
    margin-left: 0.25em;
    text-align: left;
    width: 4em;
  }
}

.SelectContainer select {
  height: 2em;
  box-sizing: border-box;
}

@media only screen and (max-width: 600px) {
  .BagItem .Buttons > *:first-child,
  .HideOnMobile,
  .HideOnMobile * {
    display: none;
  }
}
