.AdminApplications {
  max-width: 960px;
  margin: 2em auto;

  & ul {
    padding: 0;
    & li {
      display: grid;
      grid-template-columns: 1fr 1fr;
    }
  }
}
