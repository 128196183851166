@import "../Shared/vars.scss";

.Sustainability--Grid {
  display: grid;
  grid-column-gap: 1em;
  @media screen and (max-width: 768px) {
    grid-template-columns: auto;
  }
}

.Sustainability {
  display: flex;
  flex-direction: column;
  width: 100%;

  @media screen and (min-width: 768px) {
    & > section {
      margin-bottom: 4em;
    }
  }
  @media screen and (max-width: 768px) {
    & > section > div {
      box-sizing: border-box;
      margin: 1em;
    }
  }

  & h1 {
    @include font-heading--medium;

    @media screen and (max-width: 768px) {
      font-size: 2.5em;
      grid-template-columns: auto;
    }
    @media screen and (min-width: 768px) {
      font-size: 3em;
      margin: 1rem;
    }
    box-sizing: border-box;
  }
  & h2 {
    @include font-heading--regular;
  }

  & img {
    width: 100%;
    object-fit: cover;
    box-sizing: border-box;
  }

  & .Main {
    @extend .Sustainability--Grid;
    background: #efefef;
    box-sizing: border-box;

    & img {
      width: 100%;
      padding: 2em;
    }

    & p {
      margin: 0;
    }

    @media screen and (min-width: 768px) {
      & > div {
        padding: 4em 1em;
      }

      & img {
        padding: 0;
      }

      & p {
        margin: 1em;
      }

      & > * {
        height: inherit;
        box-sizing: border-box;
      }
    }
  }

  & .Mission {
    @extend .Sustainability--Grid;
    text-align: right;
  }

  .Cards {
    @extend .Sustainability--Grid;
    grid-column-gap: 4em;
  }
  & .LeftCard {
    background: #efefef;
    box-sizing: border-box;
    padding: 1em 3em;
  }
  & .RightCard {
    background: #efefef;
    box-sizing: border-box;
    padding: 1em 3em;
  }

  .Garments {
    @extend .Sustainability--Grid;

    & img {
      padding: 2em;
      box-sizing: border-box;

      @media screen and (min-width: 768px) {
        padding-left: 3em;
      }
      height: inherit;
    }
  }

  .Packaging {
    @extend .Sustainability--Grid;
    background: #efefef;
    grid-column-gap: 0;
    text-align: right;
    box-sizing: border-box;
    & > div {
      @media screen and (min-width: 768px) {
        margin: 0 2em;
      }

      box-sizing: border-box;
    }
  }

  .End {
    @extend .Sustainability--Grid;
    @include font-heading--regular;
    font-weight: bold;

    & > div {
      background: #efefef;
      padding: 2em;

      @media only screen and (min-width: 768px) {
        padding: 1em 7em 1em 2em;
      }

      box-sizing: border-box;
      & p {
        margin-bottom: 1.5em;
      }
    }
  }
}

@mixin center {
  max-width: 960px;
  margin: 0 auto;
}
@media only screen and (min-width: 768px) {
  .Sustainability {
    margin: 0 auto;
  }
  .Main {
    grid-template-columns: 55fr 45fr;
    height: 30em;
    & > div {
      margin-left: 20%;
    }
  }
  .Mission {
    grid-template-columns: 4fr 6fr;
    @include center;
  }
  .Garments {
    @include center;
    grid-template-columns: 1fr 1fr;
    height: 25em;
  }
  .Cards {
    @include center;
    grid-template-columns: 1fr 1fr;
  }
  .Packaging {
    @include center;
    grid-template-columns: 35fr 65fr;
  }
  .End {
    @include center;
    grid-template-columns: 1fr 1fr;
    height: 35em;
    & img {
      object-fit: cover;
    }
    & > * {
      height: inherit;
    }
  }
}
