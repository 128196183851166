@import "../Shared/vars.scss";

.MoveToBag {
  display: flex;
  & svg {
    margin-right: 0.25em;
  }
}

@media only screen and (min-width: 768px) {
  .MoveToBag {
    display: flex;
  }
}
